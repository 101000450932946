import * as yup from 'yup'

export const stringBlankOrGeqThree = yup
  .string()
  .trim()
  .test(
    'len',
    'Must be empty or at least 3 characters',
    (val) => val!.length === 0 || val!.length >= 3
  )

export const checkIntQuantity = (name: string) =>
  yup
    .number()
    .integer()
    .required(`Please enter ${name}`)
    .typeError(`${name} must be a number`)
    .min(
      0,
      `${name} must be non-negative integer if unit of measure is discrete unit`
    )
export const checkIntQuantityNullable = (name: string) =>
  yup
    .number()
    .integer()
    .transform((currentValue, originalValue) => {
      return originalValue === '' ? null : currentValue
    })
    .nullable()
    .typeError(`${name} must be a number`)
    .min(
      0,
      `${name} must be non-negative integer if unit of measure is discrete unit`
    )
export const checkIntQuantityPositive = (name: string) =>
  yup
    .number()
    .integer()
    .required(`Please enter ${name}`)
    .typeError(`${name} must be a number`)
    .min(
      1,
      `${name} must be greater than zero integer if unit of measure is discrete unit`
    )

export const check2DecimalNumber = (name: string) =>
  yup
    .number()
    .typeError(`${name} must be a number`)
    .min(0, `${name} must be a non-negative number`)
    .test(
      'at most 2 decimals',
      'Please enter a number with 2 decimals',
      (value) => !isNaN(Number(value)) && Number(value!.toFixed(2)) === value
    )

export const check4DecimalNumber = (name: string) =>
  yup
    .number()
    .typeError(`${name} must be a number`)
    .min(0, `${name} must be non-negative number`)
    .test(
      'at most 4 decimals',
      'Please enter a number with 4 decimals',
      (value) => !isNaN(Number(value)) && Number(value!.toFixed(4)) === value
    )

// how to validate nullable number input: https://github.com/jquense/yup/issues/500
export const check2DecimalNumberNullable = (name: string) =>
  yup
    .number()
    .typeError(`${name} must be a number`)
    .transform((currentValue, originalValue) => {
      return originalValue === '' ? null : currentValue
    })
    .nullable()
    .typeError('Must be a number')
    .min(0, `${name} must be non-negative number`)
    .test(
      'at most 2 decimals',
      'Please enter a number with 2 decimals',
      (value) =>
        value === null ||
        value === undefined ||
        Number.isNaN(value) ||
        Number(value!.toFixed(2)) === value
    )
export const check4DecimalNumberNullable = (name: string) =>
  yup
    .number()
    .typeError(`${name} must be a number`)
    .transform((currentValue, originalValue) => {
      return originalValue === '' ? null : currentValue
    })
    .nullable()
    .typeError('Must be a number')
    .min(0, `${name} must be non-negative number`)
    .test(
      'at most 4 decimals',
      'Please enter a number with 4 decimals',
      (value) =>
        value === null ||
        value === undefined ||
        Number.isNaN(value) ||
        Number(value!.toFixed(4)) === value
    )

export const check2DecimalNumberPositive = (name: string) =>
  yup
    .number()
    .typeError(`${name} must be a number`)
    .positive(`${name} must be positive number`)
    .test(
      'at most 2 decimals',
      'Please enter a number with 2 decimals',
      (value) => !isNaN(Number(value)) && Number(value!.toFixed(2)) === value
    )

// how to validate nullable number input: https://github.com/jquense/yup/issues/500
export const checkNameNullable = (name: string, length?: number) =>
  yup
    .string()
    .nullable()
    .trim()
    .test(
      'minimal length',
      `${name} should be at least ${length || 3} characters or left empty`,
      (value) =>
        value === null ||
        value === undefined ||
        value.length === 0 ||
        value.length >= (length || 3)
    )
export const checkName = (name: string, length?: number) =>
  yup
    .string()
    .trim()
    .min(length || 3, `${name} should be at least ${length || 3} characters`)

export const checkPhone = () =>
  yup.string().matches(
    /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/, // source: https://stackoverflow.com/questions/4338267/validate-phone-number-with-javascript
    'Please enter a valid phone number'
  )

export const checkEmail = () =>
  yup
    .string()
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      'Please enter a valid email'
    )

// Based on GridSortItem from @mui/x-data-grid
export const muiGridSortModel = yup
  .array()
  .of(
    yup
      .object()
      .shape({
        field: yup.string().required(),
        sort: yup.mixed().oneOf(['asc', 'desc', null, undefined])
      })
      .required()
  )
  .required()
