import { useMemo } from 'react'

import { Option } from '../../components/FormField'
import { useGetPriceLevelsQuery } from '../../redux/api/shopMatrixApi'
import { PriceLevelEntry } from '../../sdk'
import useAccessTokenAndShopId from '../useAccessTokenAndShopId/useAccessTokenAndShopId'

export const useGetPriceLevels: (priceLevelIds: string[]) => {
  nonArchivedPriceLevelEntries: PriceLevelEntry[]
  priceLevelOptions: Option[]
} = (priceLevelIds) => {
  const { accessToken, shopId } = useAccessTokenAndShopId()

  const { data: priceLevels, isLoading: loadingPriceLevels } =
    useGetPriceLevelsQuery({
      authorization: accessToken,
      shop: shopId
    })

  const nonArchivedPriceLevelEntries = useMemo(() => {
    return (priceLevels || []).filter(
      (entry) => !entry.info!.archived || new Set(priceLevelIds).has(entry.id!)
    )
  }, [priceLevelIds, priceLevels])

  const priceLevelOptions = useMemo(() => {
    return (nonArchivedPriceLevelEntries || []).map((entry) => ({
      id: entry.id!,
      description: entry.info!.name!
    }))
  }, [nonArchivedPriceLevelEntries])

  return {
    nonArchivedPriceLevelEntries,
    priceLevelOptions,
    loadingPriceLevels
  }
}
