import * as yup from 'yup'

import { checkEmail, checkName, checkPhone } from '../../models/validation'

const PHONE_EMAIL_ERROR_MESSAGE = 'Please enter email or phone'
const LASTNAME_COMPANYNAME_ERROR_MESSAGE =
  'Please enter last name or company name'

export const NEW_CUSTOMER_VALIDATION = yup.object().shape(
  {
    firstname: yup.string(),
    lastname: yup.string().when('companyName', {
      is: (companyName: string) => !companyName || companyName.length === 0,
      then: checkName('Name', 1).required(LASTNAME_COMPANYNAME_ERROR_MESSAGE)
    }),
    companyName: yup.string().when('lastname', {
      is: (lastname: string) => !lastname || lastname.length === 0,
      then: yup.string().required(LASTNAME_COMPANYNAME_ERROR_MESSAGE)
    }),
    email: yup.string().when('phone', {
      is: (phone: string) => !phone || phone.length === 0,
      then: checkEmail().required(PHONE_EMAIL_ERROR_MESSAGE)
    }),
    phone: yup.string().when('email', {
      is: (email: string) => !email || email.length === 0,
      then: checkPhone().required(PHONE_EMAIL_ERROR_MESSAGE)
    }),
    source: yup.string().required('Please select a source'),
    otherSource: yup.string().when('source', {
      is: 'Other',
      then: yup.string().required('Please enter other source')
    })
  },
  [
    ['lastname', 'companyName'],
    ['email', 'phone']
  ]
)

export const UPDATE_PROFILE_VALIDATION = yup.object({
  realName: checkName('Name', 1)
})
