import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText
} from '@mui/material'
import React, { FC } from 'react'
import { Controller } from 'react-hook-form'

interface Props {
  name: string
  control: any
  label: string
  helperText?: string
  [x: string]: any
}

export const ControlledMuiCheckboxSingle: FC<Props> = ({
  name,
  control,
  label,
  helperText,
  ...props
}) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { value, onChange, onBlur, ref },
        fieldState: { error }
      }) => (
        <FormControl error={!!error}>
          <FormControlLabel
            control={
              <Checkbox
                checked={value}
                onChange={onChange}
                onBlur={onBlur}
                ref={ref}
                {...props}
              />
            }
            label={label}
          />
          {error && <FormHelperText>{error.message}</FormHelperText>}
          {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
      )}
    />
  )
}
