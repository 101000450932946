import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectProps
} from '@mui/material'
import React from 'react'

import { SELECT_MIN_WIDTH } from '../../styles'
import { generateYears } from '../../utils'
interface Props extends SelectProps {
  startYear: number
  endYear: number
  required?: boolean
  errorMessage?: string
}

export const YearSelect: React.FC<Props> = ({
  startYear,
  endYear,
  required,
  errorMessage,
  ...props
}) => {
  const selectYears = generateYears(startYear, endYear)

  return (
    <FormControl
      fullWidth
      required={required}
      error={!!errorMessage}
      variant="outlined"
      size="small">
      <InputLabel id="year-select-label">Year</InputLabel>
      <Select
        data-testid="YearSelect"
        labelId="year-select-label"
        id="year"
        label="Year"
        {...props}
        sx={{
          minWidth: SELECT_MIN_WIDTH
        }}>
        {selectYears.map((year) => (
          <MenuItem
            key={year.toString()}
            value={year}
            data-testid={`yearMenuItem-${year.toString()}`}>
            {year.toString()}
          </MenuItem>
        ))}
      </Select>
      {errorMessage && <FormHelperText>{errorMessage}</FormHelperText>}
    </FormControl>
  )
}
