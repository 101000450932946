import { useMemo } from 'react'

import { useGetLaborMatrixEntriesQuery } from '../../redux/api/shopMatrixApi'
import { LaborMatrixEntry } from '../../sdk'
import useAccessTokenAndShopId from '../useAccessTokenAndShopId/useAccessTokenAndShopId'

export const useGetLaborMatrices: (laborMatrixIds: string[]) => {
  allLaborMatrixEntries: LaborMatrixEntry[]
  nonArchivedLaborMatrixEntries: LaborMatrixEntry[]
  loadingLaborMatrix: boolean
} = (laborMatrixIds) => {
  const { accessToken, shopId } = useAccessTokenAndShopId()
  const { data: laborMatrixEntries, isLoading: loadingLaborMatrix } =
    useGetLaborMatrixEntriesQuery({
      authorization: accessToken,
      shop: shopId
    })

  const nonArchivedLaborMatrixEntries = useMemo(() => {
    return (laborMatrixEntries || []).filter(
      (entry) => !entry.info!.archived || new Set(laborMatrixIds).has(entry.id!)
    )
  }, [laborMatrixIds, laborMatrixEntries])

  return {
    allLaborMatrixEntries: laborMatrixEntries || [],
    nonArchivedLaborMatrixEntries,
    loadingLaborMatrix
  }
}
