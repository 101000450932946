import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material'
import { FC, useContext, useState } from 'react'

import { sdk } from '../../config'
import { FeatureFlags } from '../../models/FeatureFlags'
import { AppContext } from '../../providers/AppProvider/AppProvider'
import { AuthContext } from '../../providers/AuthProvider/AuthProvider'
import { VehicleEntry } from '../../sdk'
import { loggingAssert } from '../../utils/handleError/loggingAssert'

interface Props {
  handleClose: () => void
  vehicle: VehicleEntry | undefined
}

const LaborGuideDialog: FC<Props> = ({ handleClose, vehicle }) => {
  const { featureFlags } = useContext(AppContext)
  const { currentUser, userShopId } = useContext(AuthContext)
  const accessToken = loggingAssert('Token not set', currentUser!.accessToken)
  const [loadingTruspeedLink, setLoadingTruspeedLink] = useState(false)
  const retrieveLaborGuideLink = async () => {
    setLoadingTruspeedLink(true)
    const truspeedLink = await sdk.getTruSpeedLink({
      authorization: accessToken,
      shop: userShopId!,
      vehicle: vehicle?.id
    })
    window.open(truspeedLink, '_blank')
    setLoadingTruspeedLink(false)
  }

  return (
    <>
      {featureFlags?.[FeatureFlags.TruSpeed] ? (
        <Dialog open={true} onClose={handleClose}>
          <DialogTitle>Access Repair Manual</DialogTitle>
          <DialogContent>
            {!loadingTruspeedLink ? (
              <DialogContentText>
                Open the InTune Auto Repair manual
              </DialogContentText>
            ) : (
              <DialogContentText>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    m: 'auto',
                    width: 'fit-content'
                  }}>
                  <CircularProgress />
                  Loading Repair Manual
                </Box>
              </DialogContentText>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Close</Button>
            {!loadingTruspeedLink && (
              <Button onClick={retrieveLaborGuideLink}>Open in New tab</Button>
            )}
          </DialogActions>
        </Dialog>
      ) : (
        <Dialog open={true} onClose={handleClose}>
          <DialogTitle>Access Repair Manual</DialogTitle>
          <DialogContent>
            Unfortunately, your subscription does not include access to the
            InTune Auto Repair Manual. If you’re interested in using the InTune
            Auto Repair Manual, please reach out to info@intuneauto.com to get
            started.
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Close</Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  )
}

export default LaborGuideDialog
