import { DELIMITER } from '../../models/Const'
import { IdName } from '../../sdk'

export const convertIdNameToString = (data: IdName | undefined) => {
  if (!data) {
    return ''
  }
  return `${data.id + DELIMITER + data.name}`
}

export const convertStringToIdName = (data: string) => {
  return {
    id: Number(data.split(DELIMITER)[0]),
    name: data.split(DELIMITER)[1]
  }
}
