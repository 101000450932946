import { Box, Button, Tooltip } from '@mui/material'
import React, { useContext, useEffect, useMemo, useState } from 'react'

import useAccessTokenAndShopId from '../../hooks/useAccessTokenAndShopId/useAccessTokenAndShopId'
import { AddVehicleFn } from '../../models'
import { FeatureFlags } from '../../models/FeatureFlags'
import { AppContext } from '../../providers/AppProvider/AppProvider'
import { useCreateVehicleMutation } from '../../redux/api'
import { VehicleInformation } from '../../sdk'
import { DividerWithText } from '../SharedUI/DividerWithText'
import { endYear } from '../VehicleModelSelect/useVehicleModelSelect'
import CustomVehicleForm from './CustomVehicleForm'

interface Props {
  handleAddVehicle: AddVehicleFn
  onValueChange: () => void
  customerId: string
}

export const AddCustom: React.FC<Props> = ({
  customerId,
  onValueChange,
  handleAddVehicle
}) => {
  const { accessToken, shopId } = useAccessTokenAndShopId()
  const [createVehicle] = useCreateVehicleMutation()

  const { featureFlags } = useContext(AppContext)
  const isReadOnly = featureFlags && featureFlags[FeatureFlags.ReadOnly]

  const [year, setYear] = useState(endYear - 1)
  const [make, setMake] = useState('')
  const [model, setModel] = useState('')
  const [subModel, setSubModel] = useState('')
  const [engine, setEngine] = useState('')

  useEffect(onValueChange, [onValueChange, year, make, model, subModel, engine])

  const formError = useMemo(() => {
    return !make || !model || !subModel || !engine || isReadOnly
  }, [make, model, subModel, engine, isReadOnly])

  const createNewVehicle = async () => {
    const info: VehicleInformation = {
      year,
      make: { name: make },
      model: { name: model },
      subModel: { name: subModel },
      engine: { name: engine }
    }

    await createVehicle({
      authorization: accessToken,
      newVehicleRequest: {
        customer: customerId,
        shop: shopId,
        info
      }
    })
      .unwrap()
      .then((response) => {
        handleAddVehicle(response)
      })
  }

  const bottomActions = formError ? (
    <Tooltip
      title="Please specify make model submodel and engine before submitting"
      placement="top">
      <span>
        <Button variant="contained" disabled>
          Create Vehicle
        </Button>
      </span>
    </Tooltip>
  ) : (
    <Button variant="contained" onClick={createNewVehicle}>
      Create Vehicle
    </Button>
  )

  return (
    <Box>
      <DividerWithText text="Custom" sx={{ paddingY: 4 }} />
      <CustomVehicleForm
        year={year}
        make={make}
        model={model}
        subModel={subModel}
        engine={engine}
        setYear={setYear}
        setMake={setMake}
        setModel={setModel}
        setSubModel={setSubModel}
        setEngine={setEngine}
        bottomActions={bottomActions}
      />
    </Box>
  )
}
