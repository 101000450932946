import { FC, lazy, Suspense } from 'react'
import { Route, Routes as BrowserRoutes } from 'react-router-dom'

import { AppRoutes, IntakeSubroutes } from '../../models'
import { CenteredLoading } from '../CenteredLoading/CenteredLoading'
import { retry } from './RetryComponentLoad'

const DviLoginPagePromise = import('../../pages/DviLoginPage/DviLoginPage')
const DviLoginPage = lazy(() => retry(() => DviLoginPagePromise))
const DviSignUpPagePromise = import('../../pages/DviLoginPage/DviSignUpPage')
const DviSignUpPage = lazy(() => retry(() => DviSignUpPagePromise))
const SingleInspectionContainerPromise = import('./SingleInspectionContainer')
const SingleInspectionContainer = lazy(() =>
  retry(() => SingleInspectionContainerPromise)
)

const DviContainerPromise = import('./DviContainer')
const DviContainer = lazy(() => retry(() => DviContainerPromise))

const NewAppointmentPagePromise = import(
  '../../pages/Appointments/NewAppointmentPage'
)
const NewAppointmentPage = lazy(() => retry(() => NewAppointmentPagePromise))
const AppointmentSchedulePromise = import(
  '../../pages/AppointmentSchedule/AppointmentScheduleContainer'
)
const AppointmentSchedule = lazy(() => retry(() => AppointmentSchedulePromise))
const CannedJobDetailPagePromise = import(
  '../../pages/CannedJobs/CannedJobDetailPage'
)
const CannedJobDetailPage = lazy(() => retry(() => CannedJobDetailPagePromise))
const NewCannedJobPagePromise = import(
  '../../pages/CannedJobs/NewCannedJobPage'
)
const NewCannedJobPage = lazy(() => retry(() => NewCannedJobPagePromise))
const ChatPagePromise = import('../../pages/Chat/ChatPage')
const ChatPage = lazy(() => retry(() => ChatPagePromise))
const CustomerDetailsPromise = import('../../pages/Customers/CustomerDetails')
const CustomerDetails = lazy(() => retry(() => CustomerDetailsPromise))
const CustomerRelationshipManagementPromise = import(
  '../../pages/Customers/CustomerRelationshipManagement'
)
const CustomerRelationshipManagement = lazy(() =>
  retry(() => CustomerRelationshipManagementPromise)
)
const DviHomePromise = import('../../pages/DviHome/DviHome')
const DviHome = lazy(() => retry(() => DviHomePromise))
const DviInspectionListPromise = import(
  '../../pages/DviInspections/DviInspectionList'
)
const DviInspectionList = lazy(() => retry(() => DviInspectionListPromise))

const DviJobsPromise = import('../../pages/DviJobs/DviJobs')
const DviJobs = lazy(() => retry(() => DviJobsPromise))
const DviSingleJobPromise = import('../../pages/DviSingleJob/DviSingleJob')
const DviSingleJob = lazy(() => retry(() => DviSingleJobPromise))
const DviProfilePromise = import('../../pages/DviProfile/DviProfile')
const DviProfile = lazy(() => retry(() => DviProfilePromise))
const DviSingleInspectionSummaryPromise = import(
  '../../pages/DviSingleInspectionSummary/DviSingleInspectionSummary'
)
const DviSingleInspectionSummary = lazy(() =>
  retry(() => DviSingleInspectionSummaryPromise)
)
const DviSingleInspectionPromise = import(
  '../../pages/DviSingleInspection/DviSingleInspection'
)
const DviSingleInspection = lazy(() => retry(() => DviSingleInspectionPromise))

const EstimateDetailsPromise = import('../../pages/Estimates/EstimateDetails')
const EstimateDetails = lazy(() => retry(() => EstimateDetailsPromise))
const EstimatesPromise = import('../../pages/Estimates/Estimates')
const Estimates = lazy(() => retry(() => EstimatesPromise))
const NewEstimatePromise = import('../../pages/Estimates/NewEstimate')
const NewEstimate = lazy(() => retry(() => NewEstimatePromise))
const RepairOrderHistoryPromise = import(
  '../../pages/History/RepairOrderHistory'
)
const RepairOrderHistory = lazy(() => retry(() => RepairOrderHistoryPromise))
const CustomerPortalPromise = import(
  '../../pages/CustomerPortal/CustomerPortal'
)
const CustomerPortal = lazy(() => retry(() => CustomerPortalPromise))
const CustomerFeedbackPromise = import(
  '../../pages/CustomerFeedback/CustomerFeedback'
)
const CustomerFeedback = lazy(() => retry(() => CustomerFeedbackPromise))
const InventoryPromise = import('../../pages/Inventory/Inventory')
const Inventory = lazy(() => retry(() => InventoryPromise))
const InventoryCorePromise = import('../../pages/Inventory/InventoryCore')
const InventoryCore = lazy(() => retry(() => InventoryCorePromise))
const InventoryPartPromise = import('../../pages/Inventory/InventoryPart')
const InventoryPart = lazy(() => retry(() => InventoryPartPromise))
const InventoryReturnPromise = import('../../pages/Inventory/InventoryReturn')
const InventoryReturn = lazy(() => retry(() => InventoryReturnPromise))
const InventoryExpensePromise = import('../../pages/Inventory/Expenses')
const InventoryExpense = lazy(() => retry(() => InventoryExpensePromise))

const LoginPagePromise = import('../../pages/LoginPage/LoginPage')
const LoginPage = lazy(() => retry(() => LoginPagePromise))
const NotFoundPromise = import('../../pages/NotFound/NotFound')
const NotFound = lazy(() => retry(() => NotFoundPromise))
const PartLookupPromise = import('../../pages/PartLookup/PartLookup')
const PartLookup = lazy(() => retry(() => PartLookupPromise))
const PasswordResetPagePromise = import(
  '../../pages/PasswordResetPage/PasswordResetPage'
)
const PasswordResetPage = lazy(() => retry(() => PasswordResetPagePromise))
const AuthorizationPDFPromise = import('../../pages/Pdfs/AuthorizationPDF')
const AuthorizationPDF = lazy(() => retry(() => AuthorizationPDFPromise))
const InvoicePDFPromise = import('../../pages/Pdfs/InvoicePDF')
const InvoicePDF = lazy(() => retry(() => InvoicePDFPromise))
const RepairOrderPDFPromise = import('../../pages/Pdfs/WorkOrderPDF')
const RepairOrderPDF = lazy(() => retry(() => RepairOrderPDFPromise))
const ReportsPromise = import('../../pages/Reports/Reports')
const Reports = lazy(() => retry(() => ReportsPromise))
const SettingsPagePromise = import('../../pages/SettingsPage/SettingsPage')
const SettingsPage = lazy(() => retry(() => SettingsPagePromise))
const SignupPagePromise = import('../../pages/SignupPage/SignupPage')
const SignupPage = lazy(() => retry(() => SignupPagePromise))
const SingleInspectionPromise = import(
  '../../pages/SingleInspection/SingleInspection'
)
const SingleInspection = lazy(() => retry(() => SingleInspectionPromise))
const VehicleDetailsPromise = import('../../pages/Vehicles/VehicleDetails')
const VehicleDetails = lazy(() => retry(() => VehicleDetailsPromise))
const WorkInProgressPromise = import(
  '../../pages/WorkInProgress/WorkInProgress'
)
const WorkInProgress = lazy(() => retry(() => WorkInProgressPromise))
const InventoryProviderPromise = import(
  '../../providers/InventoryProvider/InventoryProvider'
)
const InventoryProvider = lazy(() => retry(() => InventoryProviderPromise))
const NewAppointmentProviderPromise = import(
  '../../providers/NewAppointmentProvider/NewAppointmentProvider'
)
const NewAppointmentProvider = lazy(() =>
  retry(() => NewAppointmentProviderPromise)
)

const AuthContextInvoiceProviderPromise = import(
  '../../providers/InvoiceProvider/AuthContextInvoiceProvider'
)
const AuthContextInvoiceProvider = lazy(() =>
  retry(() => AuthContextInvoiceProviderPromise)
)

const CustomerPortalInvoiceProviderPromise = import(
  '../../providers/InvoiceProvider/CustomerPortalInvoiceProvider'
)
const CustomerPortalInvoiceProvider = lazy(() =>
  retry(() => CustomerPortalInvoiceProviderPromise)
)

const RepairOrderUrlProviderPromise = import(
  '../../providers/RepairOrderProvider/RepairOrderUrlProvider'
)
const RepairOrderUrlProvider = lazy(() =>
  retry(() => RepairOrderUrlProviderPromise)
)
const AppContainerPromise = import('../AppContainer/AppContainer')
const AppContainer = lazy(() => retry(() => AppContainerPromise))
const RequireAdminPromise = import('../RequireAuth/RequireAdmin')
const RequireAdmin = lazy(() => retry(() => RequireAdminPromise))
const RequireAuthPromise = import('../RequireAuth/RequireAuth')
const RequireAuth = lazy(() => retry(() => RequireAuthPromise))
const RequireShopPromise = import('../RequireShop/RequireShop')
const RequireShop = lazy(() => retry(() => RequireShopPromise))
const RepairOrderPortalProviderPromise = import(
  '../../providers/RepairOrderPortalProvider/RepairOrderPortalProvider'
)
const RepairOrderPortalProvider = lazy(() =>
  retry(() => RepairOrderPortalProviderPromise)
)
const PaymentPortalProviderPromise = import(
  '../../providers/PaymentPortalProvider/PaymentPortalProvider'
)
const PaymentPortalProvider = lazy(() =>
  retry(() => PaymentPortalProviderPromise)
)
const PaymentPortalPromise = import('../../pages/PaymentPortal')
const PaymentPortal = lazy(() => retry(() => PaymentPortalPromise))

const TimeClockPagePromise = import('../../pages/TimeClock/TimeClockPage')
const TimeClockPage = lazy(() => retry(() => TimeClockPagePromise))
const TimeClockUserPagePromise = import(
  '../../pages/TimeClock/TimeClockUserPage'
)
const TimeClockUserPage = lazy(() => retry(() => TimeClockUserPagePromise))

const CounterSalesProviderPromise = import(
  '../../providers/CounterSalesProvider/CounterSalesProvider'
)
const CounterSalesProvider = lazy(() =>
  retry(() => CounterSalesProviderPromise)
)

const CounterSalesPromise = import('../../pages/CounterSales/CounterSales')
const CounterSalesPage = lazy(() => retry(() => CounterSalesPromise))
const CounterSalesHistoryPromise = import(
  '../../pages/CounterSales/CounterSalesHistoryPage'
)
const CounterSalesHistory = lazy(() => retry(() => CounterSalesHistoryPromise))

const SingleInspectionUrlProviderPromise = import(
  '../../providers/SingleInspectionProvider/SingleInspectionUrlProvider'
)
const SingleInspectionUrlProvider = lazy(() =>
  retry(() => SingleInspectionUrlProviderPromise)
)

const ExpenseCardPagePromise = import('../../pages/ExpenseCard/ExpenseCardPage')
const ExpenseCardPage = lazy(() => retry(() => ExpenseCardPagePromise))

const IntakePromise = import('../../pages/Intake')
const Intake = lazy(() => retry(() => IntakePromise))
const CustomerSearchPromise = import('../../pages/Intake/IntakeCustomerSearch')
const CustomerSearch = lazy(() => retry(() => CustomerSearchPromise))
const IntakeVerificationPromise = import(
  '../../pages/Intake/IntakeVerification'
)
const IntakeVerification = lazy(() => retry(() => IntakeVerificationPromise))
const SelectVehiclePromise = import('../../pages/Intake/IntakeSelectVehicle')
const SelectVehicle = lazy(() => retry(() => SelectVehiclePromise))
const SelectServicesPromise = import('../../pages/Intake/IntakeSelectServices')
const SelectServices = lazy(() => retry(() => SelectServicesPromise))
const CreateCustomerPromise = import('../../pages/Intake/IntakeCreateCustomer')
const CreateCustomer = lazy(() => retry(() => CreateCustomerPromise))
const CreateVehiclePromise = import('../../pages/Intake/IntakeCreateVehicle')
const CreateVehicle = lazy(() => retry(() => CreateVehiclePromise))
const CustomerFormsFinishedPromise = import('../../pages/Intake/FinishedScreen')
const CustomerFormsFinished = lazy(() =>
  retry(() => CustomerFormsFinishedPromise)
)

export const Routes: FC = () => (
  <Suspense fallback={<CenteredLoading />}>
    <BrowserRoutes>
      <Route
        path={AppRoutes.PAYMENT_PORTAL}
        element={
          <PaymentPortalProvider>
            <PaymentPortal />
          </PaymentPortalProvider>
        }
      />
      <Route
        path={AppRoutes.INSPECTION_RESULTS}
        element={
          <RepairOrderPortalProvider inspection>
            <CustomerPortal />
          </RepairOrderPortalProvider>
        }
      />
      <Route
        path={AppRoutes.CUSTOMER_PORTAL}
        element={
          <RepairOrderPortalProvider>
            <CustomerPortal />
          </RepairOrderPortalProvider>
        }
      />
      <Route
        path={AppRoutes.CUSTOMER_PORTAL_INVOICE}
        element={
          <CustomerPortalInvoiceProvider>
            <InvoicePDF isCustomerCopy={true} disableAutoPrint />
          </CustomerPortalInvoiceProvider>
        }
      />
      <Route
        path={AppRoutes.CUSTOMER_FEEDBACK_PAGE}
        element={
          <RepairOrderPortalProvider>
            <CustomerFeedback />
          </RepairOrderPortalProvider>
        }
      />
      <Route element={<AppContainer />}>
        <Route element={<RequireAuth />}>
          <Route element={<RequireShop />}>
            <Route
              path={AppRoutes.COUNTER_SALES}
              element={
                <InventoryProvider>
                  <CounterSalesProvider>
                    <CounterSalesPage />
                  </CounterSalesProvider>
                </InventoryProvider>
              }
            />
            <Route
              path={AppRoutes.COUNTER_SALES_HISTORY}
              element={
                <InventoryProvider>
                  <CounterSalesProvider>
                    <CounterSalesHistory />
                  </CounterSalesProvider>
                </InventoryProvider>
              }
            />

            <Route element={<DviContainer />}>
              <Route path={AppRoutes.DVI_HOME} element={<DviHome />} />
              <Route path={AppRoutes.DVI_JOBS} element={<DviJobs />} />
              <Route
                path={AppRoutes.DVI_SINGLE_JOB}
                element={
                  <RepairOrderUrlProvider>
                    <DviSingleJob />
                  </RepairOrderUrlProvider>
                }
              />
              <Route
                path={AppRoutes.DVI_INSPECTIONS}
                element={<DviInspectionList />}
              />
              <Route element={<SingleInspectionContainer />}>
                <Route
                  path={AppRoutes.DVI_SINGLE_INSPECTION_SUMMARY}
                  element={<DviSingleInspectionSummary />}
                />
                <Route
                  path={AppRoutes.DVI_SINGLE_INSPECTION}
                  element={<DviSingleInspection />}
                />
              </Route>
              <Route path={AppRoutes.DVI_PROFILE} element={<DviProfile />} />
            </Route>
            <Route path={AppRoutes.SETTINGS} element={<SettingsPage />} />
            <Route
              path={AppRoutes.SETTINGS_CANNED_JOB_NEW}
              element={<NewCannedJobPage />}
            />
            <Route
              path={AppRoutes.SETTINGS_CANNED_JOB_DETAIL}
              element={<CannedJobDetailPage />}
            />
            <Route
              path={AppRoutes.AUTHORIZATION_PDF}
              element={
                <AuthContextInvoiceProvider>
                  <AuthorizationPDF />
                </AuthContextInvoiceProvider>
              }
            />
            <Route
              path={AppRoutes.WORK_ORDER_PDF}
              element={
                <AuthContextInvoiceProvider>
                  <RepairOrderPDF />
                </AuthContextInvoiceProvider>
              }
            />
            <Route
              path={AppRoutes.INVOICE_PDF_CUSTOMER}
              element={
                <AuthContextInvoiceProvider>
                  <InvoicePDF isCustomerCopy={true} />
                </AuthContextInvoiceProvider>
              }
            />
            <Route
              path={AppRoutes.INVOICE_PDF_MERCHANT}
              element={
                <AuthContextInvoiceProvider>
                  <InvoicePDF isCustomerCopy={false} />
                </AuthContextInvoiceProvider>
              }
            />
            <Route
              path={AppRoutes.WORK_IN_PROGRESS}
              element={<WorkInProgress />}
            />
            <Route
              path={AppRoutes.CUSTOMER_RELATIONSHIP_MANAGEMENT}
              element={<CustomerRelationshipManagement />}
            />
            <Route
              path={AppRoutes.CUSTOMER_DETAILS}
              element={<CustomerDetails />}
            />
            <Route
              path={AppRoutes.VEHICLE_DETAILS}
              element={<VehicleDetails />}
            />
            <Route path="*" element={<NotFound />} />
            <Route
              path={AppRoutes.MANAGE_APPOINTMENTS}
              element={
                <NewAppointmentProvider>
                  <NewAppointmentPage />
                </NewAppointmentProvider>
              }
            />
            <Route
              path={AppRoutes.APPOINTMENTS}
              element={
                <NewAppointmentProvider>
                  <NewAppointmentPage />
                </NewAppointmentProvider>
              }
            />
            <Route path={AppRoutes.ESTIMATES} element={<Estimates />} />
            <Route path={AppRoutes.NEW_ESTIMATE} element={<NewEstimate />} />
            <Route
              path={AppRoutes.ESTIMATE_DETAILS}
              element={
                <RepairOrderUrlProvider>
                  <EstimateDetails />
                </RepairOrderUrlProvider>
              }
            />
            <Route
              path={AppRoutes.REPAIR_ORDER_HISTORY}
              element={<RepairOrderHistory />}
            />
            <Route
              path={AppRoutes.PART_LOOKUP_DETAIL}
              element={<PartLookup />}
            />
            <Route
              path={AppRoutes.INVENTORY}
              element={
                <InventoryProvider>
                  <Inventory />
                </InventoryProvider>
              }
            />
            <Route
              path={AppRoutes.INVENTORY_PART}
              element={<InventoryPart />}
            />
            <Route
              path={AppRoutes.INVENTORY_CORE}
              element={<InventoryCore />}
            />
            <Route
              path={AppRoutes.INVENTORY_RETURN}
              element={<InventoryReturn />}
            />
            <Route
              path={AppRoutes.INVENTORY_EXPENSES}
              element={<InventoryExpense />}
            />
            <Route element={<RequireAdmin />}>
              <Route path={AppRoutes.REPORTS} element={<Reports />} />
            </Route>
            <Route path={AppRoutes.CHAT} element={<ChatPage />} />
            <Route
              path={AppRoutes.SINGLE_INSPECTION}
              element={
                <SingleInspectionUrlProvider>
                  <SingleInspection />
                </SingleInspectionUrlProvider>
              }
            />
            <Route path={AppRoutes.TIME_CLOCK} element={<TimeClockPage />} />
            <Route
              path={AppRoutes.TIME_CLOCK_USER}
              element={<TimeClockUserPage />}
            />
            <Route
              path={AppRoutes.EXPENSE_CARD}
              element={<ExpenseCardPage />}
            />
            <Route path="*" element={<NotFound />} />
          </Route>
        </Route>
        <Route path={AppRoutes.LOGIN} element={<LoginPage />} />
        <Route path={AppRoutes.DVI_LOGIN} element={<DviLoginPage />} />
        <Route path={AppRoutes.DVI_SIGNUP} element={<DviSignUpPage />} />
        <Route path={AppRoutes.SIGNUP} element={<SignupPage />} />
        <Route
          path={AppRoutes.PASSWORD_RESET}
          element={<PasswordResetPage />}
        />
        <Route
          path={AppRoutes.DVI_PASSWORD_RESET}
          element={<PasswordResetPage isDvi />}
        />
      </Route>
      <Route path={AppRoutes.INTAKE} element={<Intake />}>
        <Route index element={<CustomerSearch />} />
        <Route
          path={IntakeSubroutes.VERIFICATION}
          element={<IntakeVerification />}
        />
        <Route
          path={IntakeSubroutes.VEHICLE_SELECT}
          element={<SelectVehicle />}
        />
        <Route
          path={IntakeSubroutes.CANNED_JOB_SELECT}
          element={<SelectServices />}
        />
        <Route
          path={IntakeSubroutes.CREATE_CUSTOMER}
          element={<CreateCustomer />}
        />
        <Route
          path={IntakeSubroutes.CREATE_VEHICLE}
          element={<CreateVehicle />}
        />
        <Route
          path={IntakeSubroutes.FINISHED}
          element={<CustomerFormsFinished />}
        />
      </Route>
      <Route
        path={AppRoutes.APPOINTMENT_SCHEDULE}
        element={<AppointmentSchedule />}
      />
      <Route
        path={AppRoutes.APPOINTMENT_SCHEDULE_FINISHED}
        element={<CustomerFormsFinished />}
      />
    </BrowserRoutes>
  </Suspense>
)
