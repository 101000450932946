import { CardContent, Divider, Grid, Typography } from '@mui/material'

import { VehicleEntry } from '../../sdk'
import { describeVehicle } from '../../utils'

interface Props {
  selected: boolean
  vehicle: VehicleEntry
}

const VehicleCard = ({ selected, vehicle }: Props) => {
  return (
    <CardContent
      sx={{
        padding: '8px',
        border: selected ? '1px solid black' : ''
      }}>
      <Typography variant="h4">{describeVehicle(vehicle.info)}</Typography>
      <Divider />
      <Grid>
        <Grid container spacing={2}>
          {vehicle.info?.year && (
            <Grid item xs={6}>
              <Typography variant="body2">
                {`Year: ${vehicle.info.year}`}
              </Typography>
            </Grid>
          )}
          {vehicle.info?.make?.name && (
            <Grid item xs={6}>
              <Typography variant="body2">
                {`Make: ${vehicle.info.make.name}`}
              </Typography>
            </Grid>
          )}
          {vehicle.info?.model?.name && (
            <Grid item xs={6}>
              <Typography variant="body2">
                {`Model: ${vehicle.info.model.name}`}
              </Typography>
            </Grid>
          )}
          {vehicle.info?.subModel?.name && (
            <Grid item xs={6}>
              <Typography variant="body2">
                {`Submodel: ${vehicle.info.subModel.name}`}
              </Typography>
            </Grid>
          )}
          {vehicle.info?.engine?.name && (
            <Grid item xs={6}>
              <Typography variant="body2">
                {`Engine: ${vehicle.info.engine.name}`}
              </Typography>
            </Grid>
          )}
          {vehicle.info?.licensePlate && (
            <Grid item xs={6}>
              <Typography variant="body2">
                {`Plate: ${vehicle.info.licensePlate}`}
              </Typography>
            </Grid>
          )}
          {vehicle.info?.vin && (
            <Grid item xs={12}>
              <Typography variant="body2">
                {`VIN: ${vehicle.info?.vin}`}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
    </CardContent>
  )
}

export default VehicleCard
