import { useMemo } from 'react'

import { useGetPartsMatrixEntriesQuery } from '../../redux/api/shopMatrixApi'
import { PartsMatrixEntry } from '../../sdk'
import useAccessTokenAndShopId from '../useAccessTokenAndShopId/useAccessTokenAndShopId'

export const useGetPartsMatrices: (partsMatrixIds: string[]) => {
  allPartsMatrices: PartsMatrixEntry[]
  nonArchivedPartsMatrixEntries: PartsMatrixEntry[]
  loadingPartsMatrix: boolean
} = (partsMatrixIds) => {
  const { accessToken, shopId } = useAccessTokenAndShopId()
  const { data: partsMatrixEntries, isLoading: loadingPartsMatrix } =
    useGetPartsMatrixEntriesQuery({
      authorization: accessToken,
      shop: shopId
    })

  const nonArchivedPartsMatrixEntries = useMemo(() => {
    return (partsMatrixEntries || []).filter(
      (entry) => !entry.info!.archived || new Set(partsMatrixIds).has(entry.id!)
    )
  }, [partsMatrixIds, partsMatrixEntries])

  return {
    allPartsMatrices: partsMatrixEntries || [],
    nonArchivedPartsMatrixEntries,
    loadingPartsMatrix
  }
}
