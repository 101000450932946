import { yupResolver } from '@hookform/resolvers/yup'
import { Search } from '@mui/icons-material'
import { Box, Button } from '@mui/material'
import React, { useContext, useEffect } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'

import { FeatureFlags } from '../../models/FeatureFlags'
import { AppContext } from '../../providers/AppProvider/AppProvider'
import {
  GetMultipleVehicleInfoFromLicensePlateRegisteredCountryEnum,
  GetMultipleVehicleInfoFromLicensePlateRequest
} from '../../sdk'
import { StateLicensePlate } from '../StateLicensePlate'
import { ADD_VIA_LICENSE_PLATE_VALIDATION } from './validation'

interface Props {
  onValueChange: () => void
  onSearchVehicle: (data: GetMultipleVehicleInfoFromLicensePlateRequest) => void
  isLoading: boolean
  licenseInput?: string
}

export const AddViaLicensePlate: React.FC<Props> = ({
  onValueChange,
  onSearchVehicle,
  isLoading,
  licenseInput
}) => {
  const { featureFlags } = useContext(AppContext)
  const isReadOnly = featureFlags && featureFlags[FeatureFlags.ReadOnly]

  const {
    handleSubmit,
    control,
    watch,
    formState: { errors, isSubmitting }
  } = useForm<GetMultipleVehicleInfoFromLicensePlateRequest>({
    mode: 'onBlur',
    defaultValues: {
      registeredCountry:
        GetMultipleVehicleInfoFromLicensePlateRegisteredCountryEnum.Usa,
      registeredState: undefined,
      licensePlate: licenseInput || ''
    },
    resolver: yupResolver(ADD_VIA_LICENSE_PLATE_VALIDATION)
  })

  const licensePlate = watch('licensePlate')
  const registeredState = watch('registeredState')

  useEffect(onValueChange, [onValueChange, licensePlate, registeredState])

  const onSubmit: SubmitHandler<
    GetMultipleVehicleInfoFromLicensePlateRequest
  > = async (data) => {
    onValueChange()
    onSearchVehicle(data)
  }

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      data-testid="AddViaLicensePlateForm"
      id="licenseForm">
      <Box display="flex" flexDirection="row" width="100%">
        <Box flexGrow={1} pr={2}>
          <StateLicensePlate control={control as any} errors={errors} />
        </Box>
        <Box alignSelf="top">
          <Button
            startIcon={<Search />}
            type="submit"
            variant="contained"
            disabled={isSubmitting || isLoading || isReadOnly}
            form="licenseForm">
            Lookup
          </Button>
        </Box>
      </Box>
    </form>
  )
}
