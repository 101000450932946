import * as yup from 'yup'

export const ADD_VIA_LICENSE_PLATE_VALIDATION = yup.object({
  registeredState: yup.string().required('Please select a state'),
  licensePlate: yup.string().required('Please enter a license plate number')
})

export const ADD_VIA_VIN_VALIDATION = yup.object({
  vin: yup
    .string()
    .required('Please enter a VIN')
    .matches(/^.{3,17}$/, 'Please enter a valid VIN (3-17 characters)')
})
