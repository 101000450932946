import { Button, Stack } from '@mui/material'
import { useCallback, useState } from 'react'

import useAccessTokenAndShopId from '../../hooks/useAccessTokenAndShopId/useAccessTokenAndShopId'
import { useLinkCustomerToVehicleMutation } from '../../redux/api'
import { VehicleEntry } from '../../sdk'
import { AddCustom } from './AddCustom'
import { AddManually } from './AddManually'

interface Props {
  customerId: string
  onValueChange: () => void
  submitCallback?: (vehicle: VehicleEntry) => void
}

const AddCustomOrManualVehicle = ({
  customerId,
  onValueChange,
  submitCallback
}: Props) => {
  const { shopId, accessToken } = useAccessTokenAndShopId()
  const [manualInput, setManualInput] = useState<'manual' | 'custom'>('manual')
  const [linkCustomerToVehicle] = useLinkCustomerToVehicleMutation()
  const defaultHandleAddVehicle = useCallback(
    async (vehicle: VehicleEntry) => {
      await linkCustomerToVehicle({
        vehicle: vehicle.id!,
        shop: shopId,
        customer: customerId,
        authorization: accessToken
      })
      if (submitCallback) {
        await submitCallback(vehicle)
      }
    },
    [accessToken, customerId, linkCustomerToVehicle, shopId, submitCallback]
  )

  const cycleThroughManualInput = () => {
    if (manualInput === 'custom') {
      setManualInput('manual')
    }
    if (manualInput === 'manual') {
      setManualInput('custom')
    }
    onValueChange()
  }

  return (
    <>
      {manualInput === 'custom' && (
        <AddCustom
          customerId={customerId}
          onValueChange={onValueChange}
          handleAddVehicle={defaultHandleAddVehicle}
        />
      )}
      {manualInput === 'manual' && (
        <AddManually
          customerId={customerId}
          onValueChange={onValueChange}
          handleAddVehicle={defaultHandleAddVehicle}
        />
      )}
      <Stack direction="row" alignItems="center" justifyContent="flex-end">
        <Button onClick={cycleThroughManualInput}>
          {manualInput === 'manual' && 'Create Custom Vehicle'}
          {manualInput === 'custom' && 'Lookup Vehicle Manually'}
        </Button>
      </Stack>
    </>
  )
}

export default AddCustomOrManualVehicle
