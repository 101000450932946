import AccessAlarmIcon from '@mui/icons-material/AccessAlarm'
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined'
import CarRepairIcon from '@mui/icons-material/CarRepair'
import EventIcon from '@mui/icons-material/Event'
import HistoryIcon from '@mui/icons-material/History'
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined'
import PersonIcon from '@mui/icons-material/Person'
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer'
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong'
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'
import { FC, useContext, useMemo } from 'react'
import { generatePath } from 'react-router-dom'

import { TABLET_WIDTH_BREAKPOINT } from '../../config/breakPoints'
import { AppRoutes } from '../../models'
import { FeatureFlags } from '../../models/FeatureFlags'
import {
  AuthContext,
  NavVisibilityContext,
  WindowSizeContext
} from '../../providers'
import { AppContext } from '../../providers/AppProvider/AppProvider'
import { GetUsersWithPermissionActEnum } from '../../sdk'
import { LinkList, LinkListItem } from '../LinkList'

const TOP_MENU_ITEMS: LinkListItem[] = [
  {
    label: 'Work In Progress',
    route: AppRoutes.WORK_IN_PROGRESS,
    icon: <CarRepairIcon />
  },
  {
    label: 'Appointments',
    route: AppRoutes.APPOINTMENTS,
    activeMatchRoute: [AppRoutes.MANAGE_APPOINTMENTS],
    icon: <EventIcon />
  },
  {
    label: 'Estimates',
    route: generatePath(AppRoutes.ESTIMATES, {
      id: 'new'
    }),
    icon: <ReceiptLongIcon />
  },
  {
    label: 'Inventory',
    route: AppRoutes.INVENTORY,
    icon: <Inventory2OutlinedIcon />,
    nested: [
      {
        label: 'Parts',
        route: AppRoutes.INVENTORY,
        icon: <></>
      },
      {
        label: 'Cores',
        route: AppRoutes.INVENTORY_CORE,
        icon: <></>
      },
      {
        label: 'Returns',
        route: AppRoutes.INVENTORY_RETURN,
        icon: <></>
      },
      {
        label: 'Expenses',
        route: AppRoutes.INVENTORY_EXPENSES,
        icon: <></>
      }
    ]
  },
  {
    label: 'Order History',
    route: generatePath(AppRoutes.REPAIR_ORDER_HISTORY),
    icon: <HistoryIcon />
  },
  {
    label: 'Customers (CRM)',
    route: AppRoutes.CUSTOMER_RELATIONSHIP_MANAGEMENT,
    icon: <PersonIcon />
  },
  {
    label: 'Reports',
    route: AppRoutes.REPORTS,
    icon: <AssessmentOutlinedIcon />,
    adminOnly: true
  },
  {
    label: 'Conversations',
    route: AppRoutes.CHAT,
    icon: <QuestionAnswerIcon />
  },
  {
    label: 'Time Clock',
    route: AppRoutes.TIME_CLOCK,
    icon: <AccessAlarmIcon />
  },
  {
    label: 'Counter Sales',
    route: AppRoutes.COUNTER_SALES,
    icon: <ShoppingCartIcon />
  },
  {
    label: 'Settings',
    route: AppRoutes.SETTINGS_PERSONAL,
    icon: <SettingsSuggestIcon />
  }
]

export const TopDrawerContents: FC = () => {
  const { iconsOnly } = useContext(NavVisibilityContext)
  const { width } = useContext(WindowSizeContext)
  const { currentUser } = useContext(AuthContext)
  const { featureFlags } = useContext(AppContext)

  const overTabletBreakpoint = width >= TABLET_WIDTH_BREAKPOINT

  const filteredTopList = useMemo(() => {
    let list = TOP_MENU_ITEMS
    if (!currentUser || !featureFlags) {
      return []
    }
    if (
      !currentUser.permissions.has(
        GetUsersWithPermissionActEnum.AccessAdminReports
      )
    ) {
      list = list.filter((item) => !item.adminOnly)
    }
    if (!featureFlags[FeatureFlags.TimeClock]) {
      list = list.filter((item) => item.label !== 'Time Clock')
    }
    if (!featureFlags[FeatureFlags.CounterSales]) {
      list = list.filter((item) => item.label !== 'Counter Sales')
    }
    return list
  }, [currentUser, featureFlags])

  return (
    <LinkList
      listItems={filteredTopList}
      iconsOnly={iconsOnly && overTabletBreakpoint}
    />
  )
}
