import { Stack } from '@mui/material'
import React from 'react'
import { Control, Controller, FieldErrors } from 'react-hook-form'

import { VehicleInformationRegisteredStateEnum } from '../../sdk'
import { ControlledMuiTextField } from '../FormField'
import { UsStateSelect } from '../UsStateSelect'

export interface StateLicensePlateProps {
  registeredState?: VehicleInformationRegisteredStateEnum
  licensePlate?: string
}

interface Props {
  control: Control<StateLicensePlateProps>
  errors: FieldErrors<StateLicensePlateProps>
}

export function StateLicensePlate({ control, errors }: Props) {
  return (
    <Stack direction="row" spacing={2}>
      <ControlledMuiTextField
        name="licensePlate"
        required
        label="License Plate"
        control={control}
        variant="outlined"
        size="small"
        fullWidth
      />
      <Controller
        name="registeredState"
        control={control}
        render={({ field: { value, onChange } }) => (
          <UsStateSelect
            required
            value={value}
            onChange={onChange}
            textVariant="outlined"
            size="small"
            errorMessage={errors.registeredState?.message}
          />
        )}
      />
    </Stack>
  )
}
