export const labels: { [id: string]: string } = {
  firstname: 'First Name',
  lastname: 'Last Name',
  companyName: 'Company Name',
  phone: 'Phone',
  email: 'Email',
  streetAddress: 'Address',
  city: 'City',
  state: 'State',
  zipcode: 'Zip Code',
  source: 'Source',
  otherSource: 'Other Source',
  taxExempt: 'Tax Exempt',
  marketingOptOut: 'Marketing Opt-Out',
  notes: 'Notes',
  defaultLaborMatrix: 'Default Labor Pricing',
  defaultPartsMatrix: 'Default Parts Pricing',
  defaultPriceLevel: 'Default Price Level'
}
