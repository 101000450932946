import { Divider, DividerProps, Typography } from '@mui/material'
import { grey } from '@mui/material/colors'
import { FC } from 'react'

interface Props extends DividerProps {
  text: string
}

export const DividerWithText: FC<Props> = ({ text, ...props }) => {
  return (
    <Divider
      variant="middle"
      textAlign="center"
      role="presentation"
      sx={{ width: '100%', margin: 0 }}
      {...props}>
      <Typography sx={{ color: grey[600] }}>{text}</Typography>
    </Divider>
  )
}
