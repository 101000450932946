import { List, ListItemButton, ListProps } from '@mui/material'
import React, { ReactElement } from 'react'
import { Link as RouterLink, matchPath, useLocation } from 'react-router-dom'

import { AppRoutes } from '../../models'
import { ModifyObjectType } from '../../utils'
import { ListItemFull, ListItemIconOnly } from './ListItem'
import { NestedListItem } from './NestedListItem'

const MIN_HEIGHT = '48px'

export interface LinkListItem {
  label: string
  route: string
  activeMatchRoute?: AppRoutes[]
  icon: ReactElement
  nested?: LinkListItem[]
  adminOnly?: boolean
}

type Props = ModifyObjectType<
  ListProps,
  {
    listItems: LinkListItem[]
    iconsOnly?: boolean
  }
>

export function checkSelected(
  listItem: LinkListItem,
  pathname: string
): boolean {
  return !!(
    listItem.activeMatchRoute?.reduce(
      (prev, cur) => prev || !!matchPath(cur, pathname),
      false
    ) || !!matchPath(listItem.route, pathname)
  )
}
export const LinkList: React.FC<Props> = ({ listItems, iconsOnly }) => {
  const { pathname } = useLocation()

  return (
    <List component="nav">
      {listItems.map((listItem: LinkListItem) =>
        !listItem.nested ? (
          <ListItemButton
            key={listItem.route}
            selected={checkSelected(listItem, pathname)}
            component={RouterLink}
            to={listItem.route as string}
            sx={{ minHeight: MIN_HEIGHT }}>
            {iconsOnly ? (
              <ListItemIconOnly listItem={listItem} />
            ) : (
              <ListItemFull listItem={listItem} />
            )}
          </ListItemButton>
        ) : (
          <NestedListItem
            key={listItem.route}
            listItem={listItem}
            iconsOnly={iconsOnly}
            minHeight={MIN_HEIGHT}
          />
        )
      )}
    </List>
  )
}

export const LinkListExternalSite: React.FC<Props> = ({
  listItems,
  iconsOnly,
  ...props
}) => {
  const { pathname } = useLocation()

  return (
    <List {...props}>
      {listItems.map((listItem: LinkListItem) => (
        <ListItemButton
          key={listItem.route}
          component={RouterLink}
          to={pathname}
          sx={{ minHeight: '48px' }}
          onClick={() => window.open(listItem.route, '_blank')}>
          {iconsOnly ? (
            <ListItemIconOnly listItem={listItem} />
          ) : (
            <ListItemFull listItem={listItem} />
          )}
        </ListItemButton>
      ))}
    </List>
  )
}
