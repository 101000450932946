import { yupResolver } from '@hookform/resolvers/yup'
import { Search } from '@mui/icons-material'
import { Box, Button } from '@mui/material'
import React, { useContext, useEffect } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'

import { FeatureFlags } from '../../models/FeatureFlags'
import { AppContext } from '../../providers/AppProvider/AppProvider'
import { GetVehicleInfoFromVinRequest } from '../../sdk'
import { ControlledMuiTextField } from '../FormField'
import { ADD_VIA_VIN_VALIDATION } from './validation'

interface Props {
  onValueChange: () => void
  isLoading: boolean
  onSearchVehicle: (vin: string) => void
}

export const AddViaVin: React.FC<Props> = ({
  onValueChange,
  isLoading,
  onSearchVehicle
}) => {
  const { featureFlags } = useContext(AppContext)
  const isReadOnly = featureFlags && featureFlags[FeatureFlags.ReadOnly]

  const {
    handleSubmit,
    control,
    watch,
    formState: { isSubmitting }
  } = useForm<GetVehicleInfoFromVinRequest>({
    mode: 'onBlur',
    defaultValues: {
      vin: ''
    },
    resolver: yupResolver(ADD_VIA_VIN_VALIDATION)
  })

  const vin = watch('vin')

  useEffect(onValueChange, [onValueChange, vin])

  const onSubmit: SubmitHandler<GetVehicleInfoFromVinRequest> = async (
    data
  ) => {
    onValueChange()
    onSearchVehicle(data.vin!)
  }

  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        data-testid="AddViaLicensePlateForm"
        id="vinForm">
        <Box display="flex" flexDirection="row" width="100%">
          <Box flexGrow={1} pr={2}>
            <ControlledMuiTextField
              name="vin"
              control={control}
              required
              fullWidth
              label="VIN"
              variant="outlined"
              size="small"
            />
          </Box>
          <Box alignSelf="top">
            <Button
              startIcon={<Search />}
              type="submit"
              variant="contained"
              form="vinForm"
              disabled={isSubmitting || isLoading || isReadOnly}
              data-testid="SubmitAddVehicle">
              Lookup
            </Button>
          </Box>
        </Box>
      </form>
    </>
  )
}
