export function convertEmptyStringToUndefined(
  input: string | undefined
): string | undefined {
  if (!input) {
    return undefined
  }
  return input
}
export const NONE_ID = 'None'
export function convertNoneStringToUndefined(
  input: string | undefined
): string | undefined {
  if (!input || input === 'None') {
    return undefined
  }
  return input
}
export function convertObjectWithEmptyStringToUndefined(map: {
  [key: string]: string | undefined
}): { [key: string]: string | undefined } {
  const newMap: { [key: string]: string | undefined } = {}
  Object.keys(map).forEach(
    (key) => (newMap[key] = convertEmptyStringToUndefined(map[key]))
  )
  return newMap
}
