import { MenuBook } from '@mui/icons-material'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import PointOfSaleIcon from '@mui/icons-material/PointOfSale'
import {
  Box,
  Divider,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip
} from '@mui/material'
import { FC, useContext, useState } from 'react'

import { TABLET_WIDTH_BREAKPOINT } from '../../config/breakPoints'
import { AppRoutes } from '../../models'
import { FeatureFlags } from '../../models/FeatureFlags'
import {
  AuthContext,
  NavVisibilityContext,
  WindowSizeContext
} from '../../providers'
import { AppContext } from '../../providers/AppProvider/AppProvider'
import { GetUsersWithPermissionActEnum } from '../../sdk'
import LaborGuideDialog from '../Estimates/LaborGuideDialog'
import { LinkListExternalSite, LinkListItem } from '../LinkList'

const BOTTOM_MENU_ITEMS: LinkListItem[] = [
  {
    label: 'Payment',
    route: AppRoutes.PAYMENT_WEBSITE,
    icon: <PointOfSaleIcon />,
    adminOnly: true
  },
  {
    label: 'Help',
    route: AppRoutes.HELP_WEBSITE,
    icon: <HelpOutlineIcon />
  }
]

export const BottomDrawerContents: FC = () => {
  const { iconsOnly } = useContext(NavVisibilityContext)
  const { width } = useContext(WindowSizeContext)
  const { currentUser } = useContext(AuthContext)
  const { featureFlags } = useContext(AppContext)

  const overTabletBreakpoint = width >= TABLET_WIDTH_BREAKPOINT

  const [laborGuideOpen, setLaborGuideOpen] = useState(false)

  return (
    <>
      {laborGuideOpen && (
        <LaborGuideDialog
          handleClose={() => setLaborGuideOpen(false)}
          vehicle={undefined}
        />
      )}

      <Box
        sx={{
          '.MuiButtonBase-root.Mui-selected': {
            bgcolor: 'rgba(255, 255, 255, 0.08)',
            ':hover': { bgcolor: 'rgba(255, 255, 255, 0.08)' }
          },
          '.MuiButtonBase-root:hover': {
            bgcolor: 'rgba(255, 255, 255, 0.08)'
          },
          bottom: 0,
          width: '100%'
        }}>
        <Divider sx={{ bgcolor: 'primary.light' }} />

        {featureFlags![FeatureFlags.TruSpeed] && (
          <ListItemButton
            sx={{ minHeight: '48px' }}
            onClick={() => setLaborGuideOpen(true)}>
            {iconsOnly ? (
              <>
                <Tooltip title={'Repair Manual'} placement="right">
                  <ListItemIcon sx={{ color: 'primary.contrastText' }}>
                    <MenuBook />
                  </ListItemIcon>
                </Tooltip>
              </>
            ) : (
              <>
                <ListItemIcon sx={{ color: 'primary.contrastText' }}>
                  <MenuBook />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{ whiteSpace: 'nowrap' }}>
                  Repair Manual
                </ListItemText>
              </>
            )}
          </ListItemButton>
        )}

        <LinkListExternalSite
          listItems={
            currentUser &&
            currentUser.permissions.has(
              GetUsersWithPermissionActEnum.AccessAdminReports
            )
              ? BOTTOM_MENU_ITEMS
              : BOTTOM_MENU_ITEMS.filter((item) => !item.adminOnly)
          }
          iconsOnly={iconsOnly && overTabletBreakpoint}
          sx={{ pt: 0 }}
        />
      </Box>
    </>
  )
}
