import { Box, FormHelperText } from '@mui/material'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'

import useAccessTokenAndShopId from '../../hooks/useAccessTokenAndShopId/useAccessTokenAndShopId'
import { AddVehicleFn } from '../../models'
import { useCreateVehicleMutation } from '../../redux/api'
import { VehicleInformation } from '../../sdk'
import { convertStringToIdName } from '../../utils/convertIdName/convertIdName'
import { DividerWithText } from '../SharedUI/DividerWithText'
import { useVehicleModelSelect } from '../VehicleModelSelect/useVehicleModelSelect'

interface Props {
  handleAddVehicle: AddVehicleFn
  onValueChange: () => void
  customerId: string
}

export type FormValues = {}

export const AddManually: React.FC<Props> = ({
  customerId,
  onValueChange,
  handleAddVehicle
}) => {
  const { accessToken, shopId } = useAccessTokenAndShopId()

  const [createVehicle] = useCreateVehicleMutation()
  const { handleSubmit } = useForm<FormValues>({})
  const {
    year,
    make,
    model,
    subModel,
    engine,
    disabledYMM,
    vehicleModelSelect
  } = useVehicleModelSelect(undefined, true)

  useEffect(onValueChange, [onValueChange, year, make, model, subModel, engine])

  const onSubmit = async () => {
    const info: VehicleInformation = {
      year,
      make: convertStringToIdName(make),
      model: convertStringToIdName(model),
      subModel: convertStringToIdName(subModel),
      engine: convertStringToIdName(engine)
    }
    await createVehicle({
      authorization: accessToken,
      newVehicleRequest: {
        customer: customerId,
        shop: shopId,
        info
      }
    })
      .unwrap()
      .then((response) => {
        handleAddVehicle(response)
      })
  }

  return (
    <Box>
      <DividerWithText text="Manual" sx={{ paddingY: 4 }} />
      <form onSubmit={handleSubmit(onSubmit)} id="manualVehicleForm">
        {vehicleModelSelect}
      </form>
      {disabledYMM && (
        <FormHelperText>
          Please select year, make, model, sub model and engine
        </FormHelperText>
      )}
    </Box>
  )
}
