import { CustomerInformation, CustomerInformationSourceEnum } from '../../sdk'
import { NONE_ID } from '../../utils/convertEmptyStringToUndefined/convertEmptyStringToUndefined'

export const newCustomerDefaultValues: CustomerInformation = {
  firstname: '',
  lastname: '',
  companyName: '',
  email: '',
  phone: '',
  streetAddress: '',
  city: '',
  state: undefined,
  zipcode: '',
  source: CustomerInformationSourceEnum.WalkIn,
  otherSource: '',
  taxExempt: false,
  marketingOptOut: false,
  notes: '',
  defaultPriceLevel: NONE_ID,
  defaultLaborMatrix: NONE_ID,
  defaultPartsMatrix: NONE_ID
}
