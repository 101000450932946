import {
  Alert,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  useTheme
} from '@mui/material'
import React, { FC, useCallback, useContext } from 'react'
import { SubmitHandler } from 'react-hook-form'

import { FeatureFlags } from '../../models/FeatureFlags'
import { AuthContext } from '../../providers'
import { AppContext } from '../../providers/AppProvider/AppProvider'
import { useCreateCustomerMutation } from '../../redux/api'
import { CustomerEntry, CustomerInformation } from '../../sdk'
import { convertNoneStringToUndefined } from '../../utils/convertEmptyStringToUndefined/convertEmptyStringToUndefined'
import { loggingAssert } from '../../utils/handleError/loggingAssert'
import { NewCustomerForm } from '../Customers'
import { newCustomerDefaultValues } from '../Customers/newCustomerDefaultValues'

interface Props {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  handleCustomerChange?: (customer: CustomerEntry) => void
  customerName: string
}

export const AddCustomerDialog: FC<Props> = ({
  open,
  setOpen,
  handleCustomerChange,
  customerName
}) => {
  const { currentUser, userShopId } = useContext(AuthContext)
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

  const { featureFlags } = useContext(AppContext)
  const isReadOnly = featureFlags && featureFlags[FeatureFlags.ReadOnly]

  const customerNameArray = customerName.split(' ')
  const firstname = customerNameArray.length > 1 ? customerNameArray[0] : ''
  const lastname =
    customerNameArray.length === 1
      ? customerNameArray[0]
      : customerNameArray.length > 1
      ? customerNameArray.filter((element, index) => index !== 0).join(' ')
      : ''

  const [createCustomer] = useCreateCustomerMutation()

  const onSubmit: SubmitHandler<CustomerInformation> = useCallback(
    (data) => {
      setOpen(false)
      createCustomer({
        newCustomerRequest: {
          customerInformation: {
            ...data,
            state: data.state || undefined,
            defaultPriceLevel: convertNoneStringToUndefined(
              data.defaultPriceLevel
            ),
            defaultLaborMatrix: convertNoneStringToUndefined(
              data.defaultLaborMatrix
            ),
            defaultPartsMatrix: convertNoneStringToUndefined(
              data.defaultPartsMatrix
            ),
            shop: loggingAssert('ShopId not set', userShopId)
          },
          vehicles: []
        },
        authorization: loggingAssert('Token not set', currentUser?.accessToken)
      })
        .unwrap()
        .then((response) => {
          if (handleCustomerChange) {
            handleCustomerChange({
              id: response,
              information: data
            })
          }
        })
    },
    [
      createCustomer,
      currentUser?.accessToken,
      handleCustomerChange,
      setOpen,
      userShopId
    ]
  )

  return (
    <Dialog
      open={open}
      fullScreen={fullScreen}
      onClose={() => {
        setOpen(false)
      }}>
      {isReadOnly && (
        <Alert severity="warning" variant="filled">
          READ ONLY
        </Alert>
      )}

      <DialogTitle>Add a New Customer</DialogTitle>
      <DialogContent>
        <Box pt={2}>
          <NewCustomerForm
            onSubmit={onSubmit}
            defaultValues={{
              ...newCustomerDefaultValues,
              firstname,
              lastname
            }}
            cancelCallback={() => {
              setOpen(false)
            }}
            isReadOnly={isReadOnly}
          />
        </Box>
      </DialogContent>
    </Dialog>
  )
}
