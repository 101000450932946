import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { PartTaxonomy } from '../../sdk'
import {
  convertNoneStringToUndefined,
  NONE_ID
} from '../../utils/convertEmptyStringToUndefined/convertEmptyStringToUndefined'
import { generateNodeIdFromPartTaxonomy } from '../../utils/convertTaxonomy/convertTaxonomy'
import { useGetLaborMatrices } from '../useGetLaborMatrices/useGetLaborMatrices'
import { useGetPartsMatrices } from '../useGetPartsMatrices/useGetPartsMatrices'
import { useGetPriceLevels } from '../useGetPriceLevels/useGetPriceLevels'

const NoneOption = {
  id: NONE_ID,
  description: NONE_ID
}

export interface SelectedPricingInterface {
  selectedLaborMatrixId: string
  selectedPartsMatrixId: string
}

// initial ids determine starting state for price matrix
// Order of importance for ids: parts matrix, labor matrix, price level
// if any ids === '', they are ignored
// NONE_ID indicates that we want to fix the starting labor or parts matrix to NONE
export const useSelectPricingMatrix = (
  initPriceLevelId: string,
  initLaborMatrixId: string,
  initPartsMatrixId: string,
  defaultSelectionParams?: {
    partTaxonomy?: PartTaxonomy
    brand?: string
    brandDisplayName?: string
  }
) => {
  // state for selection
  const [selectedPriceLevelId, setSelectedPriceLevelId] = useState(
    initPriceLevelId === '' ? NONE_ID : initPriceLevelId
  )
  const [selectedPricing, setSelectedPricing] =
    useState<SelectedPricingInterface>({
      selectedLaborMatrixId:
        initLaborMatrixId === '' ? NONE_ID : initLaborMatrixId,
      selectedPartsMatrixId:
        initPartsMatrixId === '' ? NONE_ID : initPartsMatrixId
    })

  // fetch necessary data
  const { nonArchivedPriceLevelEntries, priceLevelOptions } = useGetPriceLevels(
    [initPriceLevelId]
  )
  const { nonArchivedLaborMatrixEntries } = useGetLaborMatrices([
    initLaborMatrixId
  ])
  const { nonArchivedPartsMatrixEntries } = useGetPartsMatrices([
    initPartsMatrixId
  ])

  // memoized selected entries
  const selectedPriceLevel = useMemo(() => {
    return nonArchivedPriceLevelEntries.find(
      (entry) => entry.id === selectedPriceLevelId
    )
  }, [nonArchivedPriceLevelEntries, selectedPriceLevelId])
  const selectedLaborMatrix = useMemo(() => {
    return nonArchivedLaborMatrixEntries.find(
      (entry) => entry.id === selectedPricing.selectedLaborMatrixId
    )
  }, [nonArchivedLaborMatrixEntries, selectedPricing.selectedLaborMatrixId])
  const selectedPartsMatrix = useMemo(() => {
    return nonArchivedPartsMatrixEntries.find(
      (entry) => entry.id === selectedPricing.selectedPartsMatrixId
    )
  }, [nonArchivedPartsMatrixEntries, selectedPricing.selectedPartsMatrixId])

  // filtered matrices based on price level
  const filteredLaborMatrices = useMemo(() => {
    return nonArchivedLaborMatrixEntries.filter(
      (entry) =>
        entry.info?.priceLevel ===
        (selectedPriceLevelId === NONE_ID ? undefined : selectedPriceLevelId)
    )
  }, [nonArchivedLaborMatrixEntries, selectedPriceLevelId])
  const filteredPartsMatrices = useMemo(() => {
    return nonArchivedPartsMatrixEntries.filter(
      (entry) =>
        entry.info?.priceLevel ===
        (selectedPriceLevelId === NONE_ID ? undefined : selectedPriceLevelId)
    )
  }, [nonArchivedPartsMatrixEntries, selectedPriceLevelId])

  // filtered price level options
  const priceLevelsWithMatrices = useMemo(() => {
    const validPriceLevels = new Set<string>()
    nonArchivedLaborMatrixEntries.forEach((entry) =>
      validPriceLevels.add(entry.info?.priceLevel || NONE_ID)
    )
    nonArchivedPartsMatrixEntries.forEach((entry) =>
      validPriceLevels.add(entry.info?.priceLevel || NONE_ID)
    )
    return validPriceLevels
  }, [nonArchivedLaborMatrixEntries, nonArchivedPartsMatrixEntries])
  const filteredPriceLevelOptions = useMemo(
    () =>
      [NoneOption, ...priceLevelOptions].filter((option) =>
        priceLevelsWithMatrices.has(option.id)
      ),
    [priceLevelOptions, priceLevelsWithMatrices]
  )

  // memoized options
  const laborMatrixOptions = useMemo(
    () =>
      filteredLaborMatrices.map((entry) => ({
        id: entry.id!,
        description:
          entry.info!.description! + (entry.info!.archived ? '(Outdated)' : '')
      })),
    [filteredLaborMatrices]
  )
  const partsMatrixOptions = useMemo(
    () =>
      filteredPartsMatrices.map((entry) => ({
        id: entry.id!,
        description:
          entry.info!.description! + (entry.info!.archived ? '(Outdated)' : '')
      })),
    [filteredPartsMatrices]
  )

  // calculate default labor matrix based on filtered matrix options
  const defaultLaborMatrix = useMemo(() => {
    if (initLaborMatrixId === NONE_ID) {
      return undefined
    }
    const result = filteredLaborMatrices.find(
      (entry) => entry.id === initLaborMatrixId
    )
    if (result !== undefined) {
      return result
    }
    if (filteredLaborMatrices.length === 0) {
      return undefined
    }
    return filteredLaborMatrices[0]
  }, [initLaborMatrixId, filteredLaborMatrices])

  // calculate default parts matrix based on filtered matrix options
  const defaultPartsMatrix = useMemo(() => {
    if (initPartsMatrixId === NONE_ID) {
      return undefined
    }
    const result = filteredPartsMatrices.find(
      (entry) => entry.id === initPartsMatrixId
    )
    if (result !== undefined) {
      return result
    }
    if (filteredPartsMatrices.length === 0) {
      return undefined
    }
    for (let index = filteredPartsMatrices.length - 1; index > 0; index--) {
      const matrix = filteredPartsMatrices[index]
      if (
        defaultSelectionParams?.brand &&
        defaultSelectionParams?.brandDisplayName &&
        matrix.info?.brand === defaultSelectionParams.brand &&
        matrix.info.brandDisplayName === defaultSelectionParams.brandDisplayName
      ) {
        return matrix
      }
      if (
        defaultSelectionParams?.partTaxonomy &&
        generateNodeIdFromPartTaxonomy(matrix.info?.partTaxonomy) ===
          generateNodeIdFromPartTaxonomy(defaultSelectionParams?.partTaxonomy)
      ) {
        return matrix
      }
    }
    return filteredPartsMatrices[0]
  }, [
    initPartsMatrixId,
    filteredPartsMatrices,
    defaultSelectionParams?.brand,
    defaultSelectionParams?.brandDisplayName,
    defaultSelectionParams?.partTaxonomy
  ])

  // set initial price level
  const initialLaborMatrix = useMemo(
    () =>
      nonArchivedLaborMatrixEntries.find(
        (entry) => entry.id === initLaborMatrixId
      ),
    [initLaborMatrixId, nonArchivedLaborMatrixEntries]
  )
  const initialPartsMatrix = useMemo(
    () =>
      nonArchivedPartsMatrixEntries.find(
        (entry) => entry.id === initPartsMatrixId
      ),
    [initPartsMatrixId, nonArchivedPartsMatrixEntries]
  )
  const initialPriceLevel = useMemo(
    () =>
      priceLevelsWithMatrices.has(initPriceLevelId)
        ? initPriceLevelId
        : NONE_ID,
    [initPriceLevelId, priceLevelsWithMatrices]
  )

  useEffect(() => {
    const defaultPriceLevel =
      initialPartsMatrix?.info?.priceLevel ||
      initialLaborMatrix?.info?.priceLevel ||
      initialPriceLevel
    setSelectedPriceLevelId(defaultPriceLevel)
  }, [
    initialLaborMatrix?.info?.priceLevel,
    initialPartsMatrix?.info?.priceLevel,
    initialPriceLevel
  ])

  // set state to default matrix (default matrix depends on selected price level)
  useEffect(() => {
    setSelectedPricing({
      selectedLaborMatrixId:
        defaultLaborMatrix?.id === undefined ? NONE_ID : defaultLaborMatrix?.id,
      selectedPartsMatrixId:
        defaultPartsMatrix?.id === undefined ? NONE_ID : defaultPartsMatrix?.id
    })
  }, [defaultLaborMatrix?.id, defaultPartsMatrix?.id])

  const SelectPriceLevelComponent = useCallback(
    ({
      variant,
      size
    }: {
      variant: 'outlined' | 'standard' | 'filled' | undefined
      size?: 'small' | 'medium' | undefined
    }) => (
      <FormControl fullWidth>
        <InputLabel>{'Price Level'}</InputLabel>
        <Select
          label="Price Level"
          variant={variant}
          size={size || 'medium'}
          value={selectedPriceLevelId}
          onChange={(event) => setSelectedPriceLevelId(event.target.value)}
          fullWidth>
          {filteredPriceLevelOptions.map(({ id, description }) => (
            <MenuItem key={id} value={id} data-testid={id}>
              {description === NONE_ID ? 'Retail' : description}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    ),
    [selectedPriceLevelId, filteredPriceLevelOptions]
  )

  const SelectLaborMatrixComponent = useCallback(
    ({
      variant,
      customNoneText
    }: {
      variant: 'outlined' | 'standard' | 'filled' | undefined
      customNoneText?: string
    }) => (
      <FormControl fullWidth>
        <InputLabel>{'Labor Matrix'}</InputLabel>
        <Select
          label="Labor Matrix"
          variant={variant}
          value={selectedPricing.selectedLaborMatrixId}
          onChange={(event) =>
            setSelectedPricing((previousPricing) => ({
              selectedLaborMatrixId: event.target.value,
              selectedPartsMatrixId: previousPricing.selectedPartsMatrixId
            }))
          }
          fullWidth>
          {[...laborMatrixOptions].map(({ id, description }) => (
            <MenuItem key={id} value={id} data-testid={id}>
              {description}
            </MenuItem>
          ))}
          {customNoneText !== '' && (
            <MenuItem
              key={NONE_ID}
              value={NONE_ID}
              data-testid={NONE_ID}
              sx={{ color: customNoneText ? 'purple' : undefined }}>
              {customNoneText || NONE_ID}
            </MenuItem>
          )}
        </Select>
      </FormControl>
    ),
    [laborMatrixOptions, selectedPricing.selectedLaborMatrixId]
  )

  const SelectPartsMatrixComponent = useCallback(
    ({
      variant,
      customNoneText
    }: {
      variant: 'outlined' | 'standard' | 'filled' | undefined
      customNoneText?: string
    }) => (
      <FormControl fullWidth>
        <InputLabel>{'Parts Matrix'}</InputLabel>
        <Select
          label="Parts Matrix"
          variant={variant}
          value={selectedPricing.selectedPartsMatrixId}
          onChange={(event) =>
            setSelectedPricing((previousPricing) => ({
              selectedLaborMatrixId: previousPricing.selectedLaborMatrixId,
              selectedPartsMatrixId: event.target.value
            }))
          }
          fullWidth>
          {[...partsMatrixOptions].map(({ id, description }) => (
            <MenuItem key={id} value={id} data-testid={id}>
              {description}
            </MenuItem>
          ))}
          {customNoneText !== '' && (
            <MenuItem
              key={NONE_ID}
              value={NONE_ID}
              data-testid={NONE_ID}
              sx={{ color: customNoneText ? 'purple' : undefined }}>
              {customNoneText || NONE_ID}
            </MenuItem>
          )}
        </Select>
      </FormControl>
    ),
    [partsMatrixOptions, selectedPricing.selectedPartsMatrixId]
  )

  return {
    selectedPriceLevelId: convertNoneStringToUndefined(selectedPriceLevelId),
    selectedLaborMatrixId: convertNoneStringToUndefined(
      selectedPricing.selectedLaborMatrixId
    ),
    selectedPartsMatrixId: convertNoneStringToUndefined(
      selectedPricing.selectedPartsMatrixId
    ),
    selectedPriceLevel,
    selectedLaborMatrix,
    selectedPartsMatrix,
    SelectPriceLevelComponent,
    SelectLaborMatrixComponent,
    SelectPartsMatrixComponent,
    setSelectedPricing
  }
}
