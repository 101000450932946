import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectProps
} from '@mui/material'
import React, { FC } from 'react'

import { IdName } from '../../sdk'
import { convertIdNameToString } from '../../utils/convertIdName/convertIdName'

interface Props extends SelectProps {
  value: string
  name: string
  options: IdName[] | undefined
}
export const ModelSelectInput: FC<Props> = ({
  value,
  name,
  options,
  ...props
}) => {
  const label = `${name.toUpperCase()} (${(options || []).length.toString()})`
  return (
    <FormControl required fullWidth variant="outlined" size="small">
      <InputLabel
        id={`${name}-label`}
        data-testid={`${name}SelectCount-${(options || []).length.toString()}`}>
        {label}
      </InputLabel>
      <Select
        data-testid={`${name}Select`}
        labelId={`${name}-label`}
        id={name}
        value={value || ''}
        label={label}
        {...props}>
        {(options || []).map((entity: IdName, index) => (
          <MenuItem
            key={index}
            value={convertIdNameToString(entity)}
            data-testid={`vehicle-${name}-${entity.id?.toString()}`}>
            {entity.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
