import { DELIMITER } from '../../models/Const'
import {
  MotorTaxonomy,
  PartsTechPartTaxonomy,
  PartTaxonomy,
  Taxonomy
} from '../../sdk'

export const generateNodeIdFromTaxonomy: (
  taxonomy: Taxonomy | undefined
) => string = (taxonomy) => {
  if (!taxonomy) {
    return ''
  }
  return [
    taxonomy.system?.id!,
    taxonomy.group?.id!,
    taxonomy.subgroup?.id!,
    taxonomy.taxonomyId
  ]
    .filter((id) => id)
    .join(DELIMITER)
}

export const generateNodeIdFromPartTaxonomy: (
  taxonomy: PartTaxonomy | undefined
) => string = (taxonomy) => {
  if (!taxonomy) {
    return ''
  }
  return [
    taxonomy.category?.id!,
    taxonomy.subcategory?.id!,
    taxonomy.partType?.id!
  ]
    .filter((id) => id)
    .join(DELIMITER)
}

const convertTaxonomyNodeIdToPathArray = (nodeId: string) => {
  return nodeId.split(DELIMITER).map((id) => Number(id))
}

export const getTaxonomyFromMotorTaxonomyTree = (
  motorTaxonomy: MotorTaxonomy | undefined,
  nodeId: string
) => {
  const result: Taxonomy = {
    system: undefined,
    group: undefined,
    subgroup: undefined,
    taxonomyId: undefined,
    literalName: undefined
  }
  if (!nodeId || !motorTaxonomy) {
    return result
  }
  const path = convertTaxonomyNodeIdToPathArray(nodeId)
  if (path[0]) {
    const system = motorTaxonomy.systems![path[0]]
    result.system = system.idName
    if (path[1]) {
      const group = system.groups![path[1]]
      result.group = group.idName!
      if (path[2]) {
        const subgroup = group.subgroups![path[2]]
        result.subgroup = subgroup.idName!
        if (path[3]) {
          result.taxonomyId = path[3]
          result.literalName = subgroup.entries![path[3]]
        }
      }
    }
  }
  return result
}

export const getTaxonomyNameMap = (
  motorTaxonomy: MotorTaxonomy | undefined,
  nodeId: string
) => {
  const result = {
    System: '',
    Group: '',
    Subgroup: '',
    Taxonomy: ''
  }
  if (!nodeId || !motorTaxonomy) {
    return result
  }
  const taxonomy = getTaxonomyFromMotorTaxonomyTree(motorTaxonomy, nodeId)
  result.System = taxonomy.system?.name || ''
  result.Group = taxonomy.group?.name || ''
  result.Subgroup = taxonomy.subgroup?.name || ''
  result.Taxonomy = taxonomy.literalName || ''

  return result
}

export const generateMotorJobName = (nameMap: {
  System: string
  Group: string
  Subgroup: string
  Taxonomy: string
}) => {
  const checked = (name: string) => {
    return name && name !== '@ALL'
  }
  return (
    (checked(nameMap.Taxonomy) && nameMap.Taxonomy) ||
    (checked(nameMap.Subgroup) && nameMap.Subgroup) ||
    (checked(nameMap.Group) && nameMap.Group) ||
    (checked(nameMap.System) && nameMap.System) ||
    ''
  )
}

export const getPartTaxonomyFromPartsTechTaxonomyTree = (
  partsTechTaxonomy: PartsTechPartTaxonomy | undefined,
  nodeId: string
) => {
  const result: PartTaxonomy = {
    category: undefined,
    subcategory: undefined,
    partType: undefined
  }
  if (!nodeId || !partsTechTaxonomy) {
    return result
  }
  const path = convertTaxonomyNodeIdToPathArray(nodeId)
  if (path[0] && partsTechTaxonomy.categories![path[0]]) {
    const category = partsTechTaxonomy.categories![path[0]]
    result.category = category.idName
    if (path[1] && category.subcategories![path[1]]) {
      const subcategory = category.subcategories![path[1]]
      result.subcategory = subcategory.idName!
      if (path[2] && subcategory.partTypes![path[2]]) {
        const partType = subcategory.partTypes![path[2]]
        result.partType = partType
      }
    }
  }
  return result
}

export const updateNodeIdWithCurrentTree = (
  partsTechTaxonomy: PartsTechPartTaxonomy | undefined,
  selectedTaxonomy: PartTaxonomy | undefined
) => {
  if (!partsTechTaxonomy || !selectedTaxonomy) {
    return undefined
  }
  const updatedTaxonomy: PartTaxonomy = {
    category: undefined,
    subcategory: undefined,
    partType: undefined
  }
  if (
    selectedTaxonomy.category &&
    selectedTaxonomy.category.id! in partsTechTaxonomy.categories!
  ) {
    const category =
      partsTechTaxonomy.categories![selectedTaxonomy.category.id!]
    updatedTaxonomy.category = category.idName
    if (
      selectedTaxonomy.subcategory &&
      selectedTaxonomy.subcategory.id! in category.subcategories!
    ) {
      const subcategory =
        category.subcategories![selectedTaxonomy.subcategory.id!]
      updatedTaxonomy.subcategory = subcategory.idName!
      if (
        selectedTaxonomy.partType &&
        selectedTaxonomy.partType.id! in subcategory.partTypes!
      ) {
        const partType = subcategory.partTypes![selectedTaxonomy.partType.id!]
        updatedTaxonomy.partType = partType
      }
    }
  }
  if (updatedTaxonomy.category) {
    return updatedTaxonomy
  }
  return updatedTaxonomy
}
