import { Button, Grid } from '@mui/material'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'

import { sdk } from '../../config'
import { useSdkFetch } from '../../hooks'
import { FeatureFlags } from '../../models/FeatureFlags'
import { AuthContext } from '../../providers'
import { AppContext } from '../../providers/AppProvider/AppProvider'
import {
  GetAllEnginesRequest,
  GetAllEnginesWithVerificationIdRequest,
  GetAllMakesRequest,
  GetAllMakesWithVerificationIdRequest,
  GetAllModelsRequest,
  GetAllModelsWithVerificationIdRequest,
  GetAllSubModelsRequest,
  GetAllSubModelsWithVerificationIdRequest
} from '../../sdk'
import { convertStringToIdName } from '../../utils/convertIdName/convertIdName'
import { ErrorMessageWrapper } from '../SharedUI'
import { YearSelect } from '../YearSelect'
import { ModelSelectInput } from './ModelSelectInput'

export const START_YEAR = 1900
export const endYear = new Date().getFullYear() + 1 // add 1 future year to account for next year's model

const getAllMakes = sdk.getAllMakes.bind(sdk)
const getAllModels = sdk.getAllModels.bind(sdk)
const getAllSubModels = sdk.getAllSubModels.bind(sdk)
const getAllEngines = sdk.getAllEngines.bind(sdk)

const getAllMakesWithVerificationId =
  sdk.getAllMakesWithVerificationId.bind(sdk)
const getAllModelsWithVerificationId =
  sdk.getAllModelsWithVerificationId.bind(sdk)
const getAllSubModelsWithVerificationId =
  sdk.getAllSubModelsWithVerificationId.bind(sdk)
const getAllEnginesWithVerificationId =
  sdk.getAllEnginesWithVerificationId.bind(sdk)

export const useVehicleModelSelect = (
  defaultValues?: {
    year: number
    model: string
    make: string
    subModel: string
    engine: string
  },
  isForm?: boolean,
  verificationId?: string,
  shopId?: string,
  customerId?: string
) => {
  const { currentUser, userShopId } = useContext(AuthContext)

  const { featureFlags } = useContext(AppContext)
  const isReadOnly = featureFlags && featureFlags[FeatureFlags.ReadOnly]

  const [year, setYear] = useState(defaultValues?.year || endYear - 1)
  const [make, setMake] = useState(defaultValues?.make || '')
  const [model, setModel] = useState(defaultValues?.model || '')
  const [subModel, setSubModel] = useState(defaultValues?.subModel || '')
  const [engine, setEngine] = useState(defaultValues?.engine || '')

  const {
    data: allMakes,
    errorMessage: errorAllMakes,
    performFetch: fetchAllMakes
  } = useSdkFetch(getAllMakes)
  const {
    data: allModels,
    errorMessage: errorAllModels,
    performFetch: fetchAllModels
  } = useSdkFetch(getAllModels)
  const {
    data: allSubModels,
    errorMessage: errorAllSubs,
    performFetch: fetchAllSubs
  } = useSdkFetch(getAllSubModels)
  const {
    data: allEngines,
    errorMessage: errorAllEngines,
    performFetch: fetchAllEngines
  } = useSdkFetch(getAllEngines)

  const {
    data: allMakesWithVerificationId,
    errorMessage: errorAllMakesWithVerificationId,
    performFetch: fetchAllMakesWithVerificationId
  } = useSdkFetch(getAllMakesWithVerificationId)
  const {
    data: allModelsWithVerificationId,
    errorMessage: errorAllModelsWithVerificationId,
    performFetch: fetchAllModelsWithVerificationId
  } = useSdkFetch(getAllModelsWithVerificationId)
  const {
    data: allSubModelsWithVerificationId,
    errorMessage: errorAllSubsWithVerificationId,
    performFetch: fetchAllSubsWithVerificationId
  } = useSdkFetch(getAllSubModelsWithVerificationId)
  const {
    data: allEnginesWithVerificationId,
    errorMessage: errorAllEnginesWithVerificationId,
    performFetch: fetchAllEnginesWithVerificationId
  } = useSdkFetch(getAllEnginesWithVerificationId)

  const firstFetch = useCallback(async () => {
    let fetchMakes:
      | ((options: GetAllMakesRequest) => Promise<void>)
      | ((options: GetAllMakesWithVerificationIdRequest) => Promise<void>) =
      fetchAllMakes

    let fetchModels:
      | ((options: GetAllModelsRequest) => Promise<void>)
      | ((options: GetAllModelsWithVerificationIdRequest) => Promise<void>) =
      fetchAllModels

    let fetchSubs:
      | ((options: GetAllSubModelsRequest) => Promise<void>)
      | ((options: GetAllSubModelsWithVerificationIdRequest) => Promise<void>) =
      fetchAllSubs

    let fetchEngines:
      | ((options: GetAllEnginesRequest) => Promise<void>)
      | ((options: GetAllEnginesWithVerificationIdRequest) => Promise<void>) =
      fetchAllEngines

    type BaseArgsType =
      | { authorization: string; shop: string; year: number }
      | { xAPIKey: string; shopId: string; customerId: string; year: number }

    let baseArgs: BaseArgsType = {
      authorization: currentUser?.accessToken || '',
      shop: userShopId || '',
      year: new Date().getFullYear()
    }

    if (verificationId) {
      fetchMakes = fetchAllMakesWithVerificationId
      fetchModels = fetchAllModelsWithVerificationId
      fetchSubs = fetchAllSubsWithVerificationId
      fetchEngines = fetchAllEnginesWithVerificationId
      baseArgs = {
        xAPIKey: verificationId,
        shopId: shopId || '',
        customerId: customerId || '',
        year: new Date().getFullYear()
      }
    }

    if (!defaultValues) {
      await fetchMakes(baseArgs as any)
      return
    }

    const promises = []

    if (defaultValues.year) {
      baseArgs.year = Number(defaultValues.year)
      promises.push(fetchMakes(baseArgs as any))
    }

    if (defaultValues.make) {
      const makeId = convertStringToIdName(defaultValues.make).id
      promises.push(
        fetchModels({
          year: baseArgs.year,
          makeId
        } as any)
      )
    }

    if (defaultValues.model) {
      const makeId = convertStringToIdName(defaultValues.make).id
      const modelId = convertStringToIdName(defaultValues.model).id
      promises.push(
        fetchSubs({
          year: baseArgs.year,
          makeId,
          modelId
        } as any)
      )
    }

    if (defaultValues.subModel) {
      const makeId = convertStringToIdName(defaultValues.make).id
      const modelId = convertStringToIdName(defaultValues.model).id
      const subModelId = convertStringToIdName(defaultValues.subModel).id
      promises.push(
        fetchEngines({
          year: baseArgs.year,
          makeId,
          modelId,
          subModelId
        } as any)
      )
    }

    await Promise.all(promises)
  }, [
    fetchAllMakes,
    fetchAllModels,
    fetchAllSubs,
    fetchAllEngines,
    currentUser?.accessToken,
    userShopId,
    verificationId,
    defaultValues,
    fetchAllMakesWithVerificationId,
    fetchAllModelsWithVerificationId,
    fetchAllSubsWithVerificationId,
    fetchAllEnginesWithVerificationId,
    shopId,
    customerId
  ])

  useEffect(() => {
    firstFetch()
  }, [firstFetch])

  const handleEngineChange = useCallback(async (newValue: string) => {
    setEngine(newValue)
  }, [])

  const handleSubModelChange = useCallback(
    async (newValue: string) => {
      setSubModel(newValue)
      handleEngineChange('')
      if (!newValue) {
        return
      }

      if (verificationId) {
        await fetchAllEnginesWithVerificationId({
          xAPIKey: verificationId,
          shopId: shopId || '',
          customerId: customerId || '',
          year,
          makeId: convertStringToIdName(make).id,
          modelId: convertStringToIdName(model).id,
          subModelId: convertStringToIdName(newValue).id
        })
      } else {
        await fetchAllEngines({
          authorization: currentUser?.accessToken || '',
          shop: userShopId || '',
          year,
          makeId: convertStringToIdName(make).id,
          modelId: convertStringToIdName(model).id,
          subModelId: convertStringToIdName(newValue).id
        })
      }
    },
    [
      currentUser?.accessToken,
      customerId,
      fetchAllEngines,
      fetchAllEnginesWithVerificationId,
      handleEngineChange,
      make,
      model,
      shopId,
      userShopId,
      verificationId,
      year
    ]
  )
  const handleModelChange = useCallback(
    async (newValue: string) => {
      setModel(newValue)
      handleSubModelChange('')
      if (!newValue) {
        return
      }

      if (verificationId) {
        await fetchAllSubsWithVerificationId({
          xAPIKey: verificationId,
          shopId: shopId || '',
          customerId: customerId || '',
          year,
          makeId: convertStringToIdName(make).id,
          modelId: convertStringToIdName(newValue).id
        })
      } else {
        await fetchAllSubs({
          authorization: currentUser?.accessToken || '',
          shop: userShopId || '',
          year,
          makeId: convertStringToIdName(make).id,
          modelId: convertStringToIdName(newValue).id
        })
      }
    },
    [
      currentUser?.accessToken,
      customerId,
      fetchAllSubs,
      fetchAllSubsWithVerificationId,
      handleSubModelChange,
      make,
      shopId,
      userShopId,
      verificationId,
      year
    ]
  )
  const handleMakeChange = useCallback(
    async (newValue: string) => {
      setMake(newValue)
      handleModelChange('')
      if (!newValue) {
        return
      }

      if (verificationId) {
        await fetchAllModelsWithVerificationId({
          xAPIKey: verificationId,
          shopId: shopId || '',
          customerId: customerId || '',
          year,
          makeId: convertStringToIdName(newValue).id
        })
      } else {
        await fetchAllModels({
          authorization: currentUser?.accessToken || '',
          shop: userShopId || '',
          year,
          makeId: convertStringToIdName(newValue).id
        })
      }
    },
    [
      currentUser?.accessToken,
      customerId,
      fetchAllModels,
      fetchAllModelsWithVerificationId,
      handleModelChange,
      shopId,
      userShopId,
      verificationId,
      year
    ]
  )
  const handleYearChange = useCallback(
    async (newValue: number) => {
      setYear(newValue)
      handleMakeChange('')

      if (verificationId) {
        await fetchAllMakesWithVerificationId({
          xAPIKey: verificationId,
          shopId: shopId || '',
          customerId: customerId || '',
          year: Number(newValue)
        })
      } else {
        await fetchAllMakes({
          authorization: currentUser?.accessToken || '',
          shop: userShopId || '',
          year: Number(newValue)
        })
      }
    },
    [
      currentUser?.accessToken,
      customerId,
      fetchAllMakes,
      fetchAllMakesWithVerificationId,
      handleMakeChange,
      shopId,
      userShopId,
      verificationId
    ]
  )

  const disabledYMM = useMemo(
    () => !year || !make || !model || !subModel || !engine,
    [engine, make, model, subModel, year]
  )

  const vehicleModelSelect = (
    <ErrorMessageWrapper
      errorMessage={
        errorAllMakes ||
        errorAllModels ||
        errorAllSubs ||
        errorAllEngines ||
        errorAllMakesWithVerificationId ||
        errorAllModelsWithVerificationId ||
        errorAllSubsWithVerificationId ||
        errorAllEnginesWithVerificationId
      }>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <YearSelect
            startYear={START_YEAR}
            endYear={endYear}
            required
            value={year}
            onChange={(e) => handleYearChange(e.target.value as number)}
          />
        </Grid>
        <Grid item xs={6}>
          <ModelSelectInput
            value={make}
            name="make"
            options={allMakes || allMakesWithVerificationId}
            onChange={(e) => handleMakeChange(e.target.value as string)}
            disabled={!year}
          />
        </Grid>
        <Grid item xs={6}>
          <ModelSelectInput
            value={model}
            name="model"
            options={allModels || allModelsWithVerificationId}
            onChange={(e) => handleModelChange(e.target.value as string)}
            disabled={!make}
          />
        </Grid>
        <Grid item xs={6}>
          <ModelSelectInput
            value={subModel}
            name="subModel"
            options={allSubModels || allSubModelsWithVerificationId}
            onChange={(e) => handleSubModelChange(e.target.value as string)}
            disabled={!model}
          />
        </Grid>
        <Grid item xs={6}>
          <ModelSelectInput
            value={engine}
            name="engine"
            options={allEngines || allEnginesWithVerificationId}
            onChange={(e) => handleEngineChange(e.target.value as string)}
            disabled={!subModel}
          />
        </Grid>
        {isForm && (
          <Grid item xs={6}>
            <Button
              type="submit"
              variant="contained"
              disabled={disabledYMM || isReadOnly}
              form="manualVehicleForm">
              Create Vehicle
            </Button>
          </Grid>
        )}
      </Grid>
    </ErrorMessageWrapper>
  )

  return {
    year,
    make,
    model,
    subModel,
    engine,
    disabledYMM,
    vehicleModelSelect
  }
}
