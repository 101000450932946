import { useCallback, useState } from 'react'

import { RawFetchDecoder, RawFetchFunction } from '../../models/sdkTypes'
import { handleSdkError } from '../../utils'

type FetchFunction<Options, Data> = (options: Options) => Promise<Data>

export interface UseSdkFetchHook<Options, Data> {
  fetching: boolean
  data?: Data
  errorMessage: string
  performFetch: (options: Options) => Promise<void>
}

export function useSdkFetch<Options, Data>(
  sdkFetchFunction: FetchFunction<Options, Data>
): UseSdkFetchHook<Options, Data> {
  const [fetching, setFetching] = useState(false)
  const [data, setData] = useState<Data | undefined>(undefined)
  const [errorMessage, setErrorMessage] = useState('')

  const performFetch = useCallback(
    async (options: Options) => {
      setFetching(true)
      try {
        const result: Data = await sdkFetchFunction(options)
        setData(result)
      } catch (e) {
        const message = (await handleSdkError(e)).message
        setErrorMessage(message)
      } finally {
        setFetching(false)
      }
    },
    [sdkFetchFunction]
  )

  return {
    fetching,
    data,
    errorMessage,
    performFetch
  }
}

export function useRawSdkFetch<Options, Data>(
  rawSdkFetchFunction: RawFetchFunction<Options, Data>,
  decoder: RawFetchDecoder<Data>
): UseSdkFetchHook<Options, Data> {
  const internalFetchFunction = useCallback(
    async (options: Options) => {
      const apiResponse = await rawSdkFetchFunction(options)
      return await decoder(apiResponse.raw)
    },
    [rawSdkFetchFunction, decoder]
  )

  const { fetching, data, errorMessage, performFetch } = useSdkFetch(
    internalFetchFunction
  )

  return {
    fetching,
    data,
    errorMessage,
    performFetch
  }
}
