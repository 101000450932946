// src: https://stackoverflow.com/questions/1575271/range-of-years-in-javascript-for-a-select-box

export const generateYears = (startYear: number, endYear: number): number[] => {
  let yearCount = startYear
  const years = []

  while (yearCount <= endYear) {
    years.push(yearCount++)
  }

  return years
}
