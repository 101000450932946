import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import { Collapse, ListItemButton, Menu, MenuItem } from '@mui/material'
import React, { useState } from 'react'
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom'

import { checkSelected, LinkListItem } from './LinkList'
import { ListItemFull, ListItemIconOnly } from './ListItem'
interface Props {
  listItem: LinkListItem
  iconsOnly?: boolean
  minHeight?: string
}
export const NestedListItem: React.FC<Props> = ({
  listItem,
  iconsOnly,
  minHeight
}) => {
  const { pathname } = useLocation()
  const [expanded, setExpanded] = useState(false)
  const navigate = useNavigate()
  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef<HTMLDivElement>(null)
  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return
    }
    setOpen(false)
  }

  return (
    <>
      <Menu
        anchorEl={anchorRef.current}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}>
        {listItem.nested!.map((subList: LinkListItem) => (
          <MenuItem
            onClick={() => {
              navigate(subList.route)
            }}
            key={subList.route}>
            {subList.label}
          </MenuItem>
        ))}
      </Menu>
      {iconsOnly ? (
        <ListItemButton
          ref={anchorRef}
          onClick={() => setOpen(!open)}
          sx={{ minHeight }}>
          <ListItemIconOnly listItem={listItem} />
        </ListItemButton>
      ) : (
        <ListItemButton
          onClick={() => setExpanded(!expanded)}
          sx={{ minHeight }}>
          <ListItemFull listItem={listItem} />
          {expanded ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
      )}
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        {listItem.nested!.map((subList: LinkListItem) => (
          <ListItemButton
            key={subList.route}
            selected={checkSelected(subList, pathname)}
            component={RouterLink}
            to={subList.route as string}>
            <ListItemFull listItem={subList} />
          </ListItemButton>
        ))}
      </Collapse>
    </>
  )
}
