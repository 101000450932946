import { TextFieldProps } from '@mui/material'
import React, { FC } from 'react'
import { Controller } from 'react-hook-form'

import { ModifyObjectType } from '../../utils'
import TextFieldNoArrowNoScroll from './TextFieldNoArrowNoScroll'

type Props = ModifyObjectType<
  TextFieldProps,
  {
    name: string
    control: any
    defaultValue?: any
    overrideValue?: any
    additionalOnChange?: (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => void
    additionalOnBlur?: (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => void
  }
>

export const ControlledMuiTextField: FC<Props> = ({
  name,
  control,
  defaultValue,
  overrideValue,
  additionalOnChange,
  additionalOnBlur,
  ...props
}) => {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({
        field: { value, onChange, onBlur, ref },
        fieldState: { error },
        formState: { errors }
      }) => (
        <TextFieldNoArrowNoScroll
          value={overrideValue !== undefined ? overrideValue : value}
          onChange={(event) => {
            onChange(event)
            if (additionalOnChange) {
              additionalOnChange(event)
            }
          }}
          onBlur={(event) => {
            onBlur()
            if (additionalOnBlur) {
              additionalOnBlur(event)
            }
          }}
          ref={ref}
          id={name}
          error={!!error || !!errors[name]}
          helperText={error?.message}
          {...props}
        />
      )}
    />
  )
}
