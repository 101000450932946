import { ComponentType } from 'react'

import { sendBugReport } from '../../utils/handleError/sendBugReport'

// function to retry lazy imports up to x times if it fails, before it errors out
// https://abiodun.dev/code-splitting-and-data-prefetch-with-react-suspense-and-lazy-loading/
export const retry: (
  fn: () => Promise<{ default: ComponentType<any> }>,
  retriesLeft?: number,
  interval?: number
) => Promise<{ default: ComponentType<any> }> = (
  fn,
  retriesLeft = 5,
  interval = 1000
) => {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        if (retriesLeft === 5) {
          sendBugReport(
            'warning',
            'Failed component load',
            'A component failed to load initially.\n' +
              'Retrying up to 5 times before throwing an error.'
          )
        }
        setTimeout(() => {
          if (retriesLeft === 1) {
            // reject('maximum retries exceeded');
            reject(error)
            return
          }

          // Passing on "reject" is the important part
          retry(fn, retriesLeft - 1, interval).then(resolve, reject)
        }, interval)
      })
  })
}
