import AddIcon from '@mui/icons-material/Add'
import ConstructionIcon from '@mui/icons-material/Construction'
import EventIcon from '@mui/icons-material/Event'
import PersonIcon from '@mui/icons-material/Person'
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong'
import {
  Button,
  ButtonGroup,
  Menu,
  MenuItem,
  Tooltip,
  useMediaQuery,
  useTheme
} from '@mui/material'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import React, { useCallback, useRef, useState } from 'react'
import { generatePath, useNavigate } from 'react-router-dom'

import { AppRoutes } from '../../models'
import { CustomerEntry } from '../../sdk'
import { AddCustomerDialog } from '../Customers/AddCustomerDialog'
import { ExpandableFab } from './ExpandableFab'

const otherOptions = ['Appointment', 'Estimate', 'Repair Order']
const otherLinks = [
  generatePath(AppRoutes.MANAGE_APPOINTMENTS, { id: 'new' }),
  AppRoutes.NEW_ESTIMATE,
  AppRoutes.NEW_ESTIMATE + '?isRepairOrder=yes'
]

export const CreateButton = () => {
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)
  const anchorRef = useRef<HTMLDivElement>(null)
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down('sm'))

  const handleAddCustomer = useCallback(
    (customer: CustomerEntry) => {
      navigate(AppRoutes.CUSTOMERS + '/' + customer.id)
    },
    [navigate]
  )

  const [openAddCustomerDialog, setOpenAddCustomerDialog] =
    useState<boolean>(false)

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    index: number
  ) => {
    setOpen(false)
    navigate(otherLinks[index])
  }

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return
    }
    setOpen(false)
  }

  return (
    <>
      <AddCustomerDialog
        open={openAddCustomerDialog}
        setOpen={setOpenAddCustomerDialog}
        handleCustomerChange={handleAddCustomer}
        customerName={''}
      />
      {matches && (
        <ExpandableFab
          handleMenuItemClick={handleMenuItemClick}
          setOpenAddCustomerDialog={setOpenAddCustomerDialog}
        />
      )}
      {!matches && (
        <>
          <ButtonGroup variant="contained" ref={anchorRef}>
            <Tooltip title={'Create Something New (\u21E7Shift + C)'}>
              <Button
                size="medium"
                onClick={handleToggle}
                startIcon={<AddIcon />}>
                Create
              </Button>
            </Tooltip>
          </ButtonGroup>
          <Menu
            anchorEl={anchorRef.current}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center'
            }}>
            <MenuItem
              key="Appointment"
              onClick={(event) => handleMenuItemClick(event, 0)}>
              <ListItemIcon>
                <EventIcon />
              </ListItemIcon>
              <ListItemText>{otherOptions[0]}</ListItemText>
            </MenuItem>
            <MenuItem
              key="Estimate"
              onClick={(event) => handleMenuItemClick(event, 1)}>
              <ListItemIcon>
                <ReceiptLongIcon />
              </ListItemIcon>
              <ListItemText>{otherOptions[1]}</ListItemText>
            </MenuItem>
            <MenuItem
              key="Repair Order"
              onClick={(event) => handleMenuItemClick(event, 2)}>
              <ListItemIcon>
                <ConstructionIcon />
              </ListItemIcon>
              <ListItemText>{otherOptions[2]}</ListItemText>
            </MenuItem>
            <MenuItem
              key="Customer"
              onClick={() => setOpenAddCustomerDialog(true)}>
              <ListItemIcon>
                <PersonIcon />
              </ListItemIcon>
              <ListItemText>Customer</ListItemText>
            </MenuItem>
          </Menu>
        </>
      )}
    </>
  )
}
