import { ListItemIcon, ListItemText, Tooltip } from '@mui/material'
import React from 'react'

import { LinkListItem } from './LinkList'

export const ListItemIconOnly = ({ listItem }: { listItem: LinkListItem }) => {
  return (
    <Tooltip title={listItem.label} placement="right">
      <ListItemIcon sx={{ color: 'primary.contrastText' }}>
        {listItem.icon}
      </ListItemIcon>
    </Tooltip>
  )
}

export const ListItemFull = ({ listItem }: { listItem: LinkListItem }) => {
  return (
    <>
      <ListItemIcon sx={{ color: 'primary.contrastText' }}>
        {listItem.icon}
      </ListItemIcon>
      <ListItemText
        primaryTypographyProps={{ whiteSpace: 'nowrap' }}
        data-testid={`LinkListItemText`}>
        {listItem.label}
      </ListItemText>
    </>
  )
}
