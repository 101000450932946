import { Box, CircularProgress } from '@mui/material'
import { BoxProps } from '@mui/system'
import React from 'react'

export const CenteredLoading: React.FC<BoxProps> = ({ ...props }) => (
  <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    sx={{ height: '100%' }}
    {...props}>
    <CircularProgress />
  </Box>
)
