import { yupResolver } from '@hookform/resolvers/yup'
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography
} from '@mui/material'
import { grey } from '@mui/material/colors'
import React, { useEffect, useRef } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'

import { useSelectPricingMatrix } from '../../hooks/useSelectPricingMatrix/useSelectPricingMatrix'
import { CustomerInformation, CustomerInformationSourceEnum } from '../../sdk'
import { labels } from '../CustomerDetail/Labels'
import {
  ControlledMuiCheckboxSingle,
  ControlledMuiTextField
} from '../FormField'
import { UsStateSelect } from '../UsStateSelect'
import { NEW_CUSTOMER_VALIDATION } from './validation'

interface Props {
  onSubmit: SubmitHandler<CustomerInformation>
  cancelCallback: () => void
  defaultValues: CustomerInformation
  showPricing?: boolean
  isReadOnly?: boolean
}

export const NewCustomerForm: React.FC<Props> = ({
  onSubmit,
  cancelCallback,
  defaultValues,
  showPricing = true,
  isReadOnly = false
}) => {
  const otherSourceInputRef = useRef<HTMLInputElement>(null)
  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
    watch,
    setValue
  } = useForm<CustomerInformation>({
    mode: 'onBlur',
    defaultValues,
    resolver: yupResolver(NEW_CUSTOMER_VALIDATION)
  })
  const source = watch('source')
  useEffect(() => {
    if (source === CustomerInformationSourceEnum.Other) {
      otherSourceInputRef?.current?.focus()
    }
  }, [source])

  const { selectedPriceLevelId, SelectPriceLevelComponent } =
    useSelectPricingMatrix(defaultValues.defaultPriceLevel || '', '', '')

  useEffect(() => {
    setValue('defaultPriceLevel', selectedPriceLevelId)
  }, [selectedPriceLevelId, setValue])
  const gridSpacing = {
    paddingBottom: 1,
    paddingRight: 1,
    alignItems: 'flex-start'
  }
  return (
    <form onSubmit={handleSubmit(onSubmit)} aria-label="NewCustomerForm">
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        rowSpacing={1}
        columnSpacing={1}
        sx={{ marginLeft: 0, marginTop: 0, width: '100%' }}>
        <Grid item xs={6} sx={gridSpacing}>
          <ControlledMuiTextField
            name="firstname"
            control={control}
            label={labels.firstname}
            fullWidth
            size="small"
          />
        </Grid>
        <Grid item xs={6} sx={gridSpacing}>
          <ControlledMuiTextField
            name="lastname"
            control={control}
            data-testid="lastNameField"
            label={labels.lastname}
            fullWidth
            size="small"
          />
        </Grid>
        <Grid item xs={6} sx={gridSpacing}>
          <ControlledMuiTextField
            name="companyName"
            control={control}
            data-testid="companyNameField"
            label={labels.companyName}
            fullWidth
            size="small"
          />
        </Grid>
        <Grid item xs={6} sx={gridSpacing}>
          <ControlledMuiTextField
            name="phone"
            control={control}
            data-testid="phoneField"
            label={labels.phone}
            fullWidth
            type="tel"
            size="small"
          />
        </Grid>
        <Grid item xs={12} sx={gridSpacing}>
          <ControlledMuiTextField
            name="email"
            control={control}
            data-testid="emailField"
            label={labels.email}
            fullWidth
            size="small"
          />
        </Grid>

        <Divider
          variant="middle"
          textAlign="center"
          role="presentation"
          sx={{ paddingY: 1, width: '100%', paddingX: 0, marginX: 2 }}>
          <Typography sx={{ color: grey[600] }}>Address</Typography>
        </Divider>

        <Grid item xs={12} sx={gridSpacing}>
          <ControlledMuiTextField
            name="streetAddress"
            control={control}
            data-testid="streetAddressField"
            label={labels.streetAddress}
            fullWidth
            size="small"
          />
        </Grid>
        <Grid item xs={6} sx={gridSpacing}>
          <ControlledMuiTextField
            name="city"
            control={control}
            data-testid="cityField"
            label={labels.city}
            fullWidth
            size="small"
          />
        </Grid>
        <Grid item xs={2} sx={gridSpacing}>
          <Controller
            name="state"
            control={control}
            render={({ field: { value, onChange } }) => (
              <UsStateSelect
                value={value}
                onChange={onChange}
                textVariant="outlined"
                size="small"
              />
            )}
          />
        </Grid>
        <Grid item xs={4} sx={gridSpacing}>
          <ControlledMuiTextField
            name="zipcode"
            control={control}
            data-testid="zipcodeField"
            label={labels.zipcode}
            fullWidth
            size="small"
          />
        </Grid>
        <Divider
          variant="middle"
          textAlign="center"
          role="presentation"
          sx={{ paddingY: 1, width: '100%', paddingX: 0, marginX: 2 }}>
          <Typography sx={{ color: grey[600] }}>Other</Typography>
        </Divider>

        <Grid item xs={6} sx={gridSpacing}>
          <Controller
            name="source"
            control={control}
            render={({
              field: { value, onChange, onBlur },
              fieldState: { error }
            }) => (
              <>
                <FormControl required fullWidth error={!!error} onBlur={onBlur}>
                  <InputLabel id="source-label">Source</InputLabel>
                  <Select
                    labelId="source-label"
                    id="source"
                    value={value}
                    label="Source"
                    size="small"
                    onChange={onChange}>
                    <MenuItem value={CustomerInformationSourceEnum.WalkIn}>
                      Walk-In
                    </MenuItem>
                    <MenuItem value={CustomerInformationSourceEnum.Google}>
                      Google
                    </MenuItem>
                    <MenuItem value={CustomerInformationSourceEnum.Facebook}>
                      Facebook
                    </MenuItem>
                    <MenuItem value={CustomerInformationSourceEnum.Yelp}>
                      Yelp
                    </MenuItem>
                    <MenuItem value={CustomerInformationSourceEnum.LocalAd}>
                      Local Ad
                    </MenuItem>
                    <MenuItem value={CustomerInformationSourceEnum.Other}>
                      Other
                    </MenuItem>
                  </Select>
                  {!!error && <FormHelperText>{error?.message}</FormHelperText>}
                </FormControl>
                {value === CustomerInformationSourceEnum.Other && (
                  <Box mt={2}>
                    <ControlledMuiTextField
                      name="otherSource"
                      control={control}
                      fullWidth
                      required
                      label="Other Source"
                      size="small"
                      inputRef={otherSourceInputRef}
                    />
                  </Box>
                )}
              </>
            )}
          />
        </Grid>
        {showPricing && (
          <Grid item xs={6} sx={gridSpacing}>
            <SelectPriceLevelComponent variant={undefined} size={'small'} />
          </Grid>
        )}
        <Grid item xs={6} sx={gridSpacing}>
          <ControlledMuiCheckboxSingle
            name="taxExempt"
            control={control}
            data-testid={'taxExemptField'}
            label={labels.taxExempt}
          />
        </Grid>
        <Grid item xs={6} sx={gridSpacing}>
          <ControlledMuiCheckboxSingle
            name="marketingOptOut"
            control={control}
            data-testid={'marketingOptOut'}
            label={labels.marketingOptOut}
          />
        </Grid>
      </Grid>
      <Stack alignItems="flex-end" mt={2}>
        <Stack direction="row" spacing={1}>
          <Button onClick={cancelCallback} data-testid="cancelButton">
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            disabled={isSubmitting || isReadOnly}>
            Save
          </Button>
        </Stack>
      </Stack>
    </form>
  )
}
