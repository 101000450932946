import {
  Alert,
  Box,
  Button,
  Card,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup
} from '@mui/material'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'

import { NO_MATCHES_STATUS, sdk } from '../../config'
import useAccessTokenAndShopId from '../../hooks/useAccessTokenAndShopId/useAccessTokenAndShopId'
import { AppContext } from '../../providers/AppProvider/AppProvider'
import {
  useCreateVehicleMutation,
  useLazyGetCustomersForVehicleQuery,
  useLinkCustomerToVehicleMutation,
  useUnlinkCustomerFromVehicleMutation
} from '../../redux/api'
import {
  CustomerEntry,
  GetMultipleVehicleInfoFromLicensePlateRequest,
  VehicleEntry,
  VehicleInformation
} from '../../sdk'
import { handleSdkError } from '../../utils'
import { formatName } from '../../utils/formatCustomerName/formatCustomerName'
import { FormMessageNoPadding } from '../FormMessage'
import { ClickableCard } from '../SharedUI/ClickableCard'
import { DividerWithText } from '../SharedUI/DividerWithText'
import AddCustomOrManualVehicle from './AddCustomOrManualVehicle'
import { AddViaLicensePlate } from './AddViaLicensePlate'
import { AddViaVin } from './AddViaVin'
import VehicleCard from './VehicleCard'

type VehicleWithOwnersEntry = {
  vehicle: VehicleEntry
  customers: CustomerEntry[]
}

type VehicleWithOwnersEntries = VehicleWithOwnersEntry[]

const quickVinLookup = sdk.quickVinLookup.bind(sdk)

export const AddVehicleByVinAndLicensePlate = ({
  customerId,
  submitCallback,
  licenseInput
}: {
  customerId: string
  submitCallback?: (vehicle: VehicleEntry) => void
  licenseInput?: string
}) => {
  const [linkCustomerToVehicle] = useLinkCustomerToVehicleMutation()
  const [unlinkCustomerFromVehicle] = useUnlinkCustomerFromVehicleMutation()
  const [createVehicle] = useCreateVehicleMutation()
  const [triggerGetCustomersForVehicle] = useLazyGetCustomersForVehicleQuery()
  const { shopId, accessToken } = useAccessTokenAndShopId()

  const [vehicleWithOwners, setVehicleWithOwners] =
    useState<VehicleWithOwnersEntry>()
  const [allVehiclesWithOwners, setAllVehiclesWithOwners] =
    useState<VehicleWithOwnersEntries>()

  const [errorMessage, setErrorMessage] = useState('')
  const [infoMessage, setInfoMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const [linkMethod, setLinkMethod] = useState<'share' | 'transfer' | ''>('')

  const resetMessages = useCallback(() => {
    setErrorMessage('')
    setInfoMessage('')
    setAllVehiclesWithOwners(undefined)
    setVehicleWithOwners(undefined)
  }, [])

  useEffect(() => {
    resetMessages()
  }, [resetMessages])

  const requiresLinkMethod =
    vehicleWithOwners && vehicleWithOwners.customers.length > 0
  const handleLink = useCallback(async () => {
    if (!vehicleWithOwners || (requiresLinkMethod && !linkMethod)) {
      return
    }
    try {
      setLoading(true)
      if (linkMethod === 'transfer') {
        for (const customer of vehicleWithOwners.customers) {
          await unlinkCustomerFromVehicle({
            authorization: accessToken,
            shop: shopId,
            customer: customer.id!,
            vehicle: vehicleWithOwners.vehicle.id!
          })
        }
      }
      await linkCustomerToVehicle({
        vehicle: vehicleWithOwners.vehicle.id!,
        shop: shopId,
        customer: customerId,
        authorization: accessToken
      })
      if (submitCallback) {
        await submitCallback(vehicleWithOwners.vehicle)
      }
    } catch (error) {
      const message = (await handleSdkError(error)).message
      setErrorMessage(message)
    } finally {
      setLoading(false)
    }
  }, [
    accessToken,
    customerId,
    linkCustomerToVehicle,
    linkMethod,
    requiresLinkMethod,
    shopId,
    submitCallback,
    unlinkCustomerFromVehicle,
    vehicleWithOwners
  ])

  const findMatchingVehicleWithOwner = useCallback(
    async (vin: string, infoWithPlateFields?: VehicleInformation) => {
      const matchingVehicles = await sdk.searchVehicleByField({
        vin,
        shop: shopId,
        authorization: accessToken
      })
      if (matchingVehicles.length > 0) {
        const vehicle = matchingVehicles[0]!
        const customers = await triggerGetCustomersForVehicle({
          authorization: accessToken,
          shop: shopId,
          vehicle: vehicle.id!
        }).unwrap()
        return { vehicle, customers }
      } else {
        let info = infoWithPlateFields
        if (
          !infoWithPlateFields ||
          !infoWithPlateFields?.year ||
          !infoWithPlateFields?.make ||
          !infoWithPlateFields?.model
        ) {
          const response = await sdk.getVehicleInfoFromVinRaw({
            authorization: accessToken,
            shop: shopId,
            vin
          })
          const status = response.raw.status
          if (status === NO_MATCHES_STATUS) {
            setInfoMessage('Sorry, no match found for this VIN')
            return
          }
          const infoCopy: Record<string, unknown> = { ...info }
          Object.keys(infoCopy).forEach(
            (key) => infoCopy[key] === undefined && delete infoCopy[key]
          )
          info = {
            ...(await response.value()),
            ...infoCopy
          }
        }
        const vehicle = await createVehicle({
          authorization: accessToken,
          newVehicleRequest: {
            customer: customerId,
            shop: shopId,
            info
          }
        }).unwrap()
        return { vehicle, customers: [] }
      }
    },
    [
      accessToken,
      createVehicle,
      customerId,
      shopId,
      triggerGetCustomersForVehicle
    ]
  )

  const { shopProfile } = useContext(AppContext)

  const onSearchVehiclesByLP = useCallback(
    async (data: GetMultipleVehicleInfoFromLicensePlateRequest) => {
      const lookupPlatesViaPartsTech: (
        data: GetMultipleVehicleInfoFromLicensePlateRequest
      ) => Promise<VehicleInformation[]> = async (data) => {
        const response = await sdk.getMultipleVehicleInfoFromLicensePlateRaw({
          ...data,
          authorization: accessToken
        })
        const status = response.raw.status
        if (status === NO_MATCHES_STATUS) {
          return []
        }
        return await response.value()
      }

      const lookupPlatesViaCarfax: (
        data: GetMultipleVehicleInfoFromLicensePlateRequest
      ) => Promise<VehicleInformation[]> = async (data) => {
        const xml = await quickVinLookup({
          authorization: accessToken,
          plate: data.licensePlate,
          state: data.registeredState,
          shop: shopId
        })
        const parser = new DOMParser()
        const xmlDocument = parser.parseFromString(xml, 'application/xml')
        const errors = xmlDocument.getElementsByTagName('errors')
        if (errors.length > 0) {
          return []
        }
        const message = xmlDocument.getElementsByTagName('message')
        if (message.length > 0) {
          return []
        }

        return Array.from(xmlDocument.getElementsByTagName('vin-info')).map(
          (vinInfoElement) => {
            const makeId = Number(
              vinInfoElement.getElementsByTagName('aces-make-id')[0]
                ?.textContent
            )
            const makeName =
              vinInfoElement.getElementsByTagName('nonoem-make')[0]?.textContent
            const modelId = Number(
              vinInfoElement.getElementsByTagName('aces-model-id')[0]
                ?.textContent
            )
            const modelName =
              vinInfoElement.getElementsByTagName('nonoem-base-model')[0]
                ?.textContent
            const submodelId = Number(
              vinInfoElement.getElementsByTagName('aces-sub-model-id')[0]
                ?.textContent
            )
            const submodelName =
              vinInfoElement.getElementsByTagName('nonoem-submodel1')[0]
                ?.textContent
            const engineId = Number(
              vinInfoElement.getElementsByTagName('aces-enginebase-id')[0]
                ?.textContent
            )
            const engineName =
              vinInfoElement.getElementsByTagName('nonoem-eng-name')[0]
                ?.textContent

            return {
              vin: vinInfoElement.getElementsByTagName('vin')[0].textContent!,
              licensePlate: data.licensePlate,
              registeredState: data.registeredState,
              registeredCountry: 'USA',
              year: Number(
                vinInfoElement.getElementsByTagName('nonoem-year')[0]
                  .textContent!
              ),
              make:
                !Number.isNaN(makeId) && makeName
                  ? {
                      id: makeId,
                      name: makeName
                    }
                  : undefined,
              model:
                !Number.isNaN(modelId) && modelName
                  ? {
                      id: modelId,
                      name: modelName
                    }
                  : undefined,
              subModel:
                !Number.isNaN(submodelId) && submodelName
                  ? {
                      id: submodelId,
                      name: submodelName
                    }
                  : undefined,
              engine:
                !Number.isNaN(engineId) && engineName
                  ? {
                      id: engineId,
                      name: engineName
                    }
                  : undefined
            }
          }
        )
      }

      try {
        setLoading(true)
        const partsTechResultsPromise = lookupPlatesViaPartsTech(data)
        const carfaxResultsPromise = shopProfile?.info?.quickVinAuthorized
          ? lookupPlatesViaCarfax(data)
          : null
        const promises = [partsTechResultsPromise]
        if (carfaxResultsPromise !== null) {
          promises.push(carfaxResultsPromise)
        }

        // Handle failure of one or both plate lookup services. Can test by modifying the lookup functions
        // to throw errors before returning.
        const vehiclesInfoArrays = await Promise.allSettled(promises)
        const vehiclesInfo = vehiclesInfoArrays
          .filter((result) => result.status === 'fulfilled')
          .map(
            (result) =>
              (result as PromiseFulfilledResult<VehicleInformation[]>).value
          )
          .flat()

        if (vehiclesInfo.length === 0) {
          setInfoMessage('No results found from license plate lookup')
          return
        }

        const matchedVehicles: VehicleWithOwnersEntry[] = []

        for (const vehicleInfo of vehiclesInfo!) {
          const matchedVehicle = await findMatchingVehicleWithOwner(
            vehicleInfo.vin!,
            vehicleInfo
          )

          if (matchedVehicle) {
            matchedVehicles.push(matchedVehicle)
          }
        }

        // duplicates are possible due to Carfax and PartsTech often returning
        // some of the same results
        const idToVehicle = new Map<string, VehicleWithOwnersEntry>()
        matchedVehicles.forEach((entry) =>
          idToVehicle.set(entry.vehicle.id!, entry)
        )
        const dedupedMatchedVehicles = Array.from(idToVehicle.values())

        // License plate lookup contains extra information that is not present
        // in VIN lookup
        setAllVehiclesWithOwners(dedupedMatchedVehicles)
      } catch (error) {
        const message = (await handleSdkError(error)).message
        setErrorMessage(message)
      } finally {
        setLoading(false)
      }
    },
    [
      accessToken,
      findMatchingVehicleWithOwner,
      shopId,
      shopProfile?.info?.quickVinAuthorized
    ]
  )

  const onSearchVehicleByVin = useCallback(
    async (vin: string) => {
      try {
        setLoading(true)
        const vehicleWithOwners = await findMatchingVehicleWithOwner(vin)
        setVehicleWithOwners(vehicleWithOwners)
      } catch (error) {
        const message = (await handleSdkError(error)).message
        setErrorMessage(message)
      } finally {
        setLoading(false)
      }
    },
    [findMatchingVehicleWithOwner]
  )

  const linkDisabled = useMemo(
    () => loading || !vehicleWithOwners || (requiresLinkMethod && !linkMethod),
    [linkMethod, loading, requiresLinkMethod, vehicleWithOwners]
  )

  return (
    <Box>
      <DividerWithText text="License Plate" sx={{ paddingY: 4 }} />
      <AddViaLicensePlate
        onValueChange={resetMessages}
        onSearchVehicle={onSearchVehiclesByLP}
        isLoading={loading}
        licenseInput={licenseInput}
      />
      <DividerWithText text="VIN" sx={{ paddingY: 4 }} />
      <AddViaVin
        onValueChange={resetMessages}
        isLoading={loading}
        onSearchVehicle={onSearchVehicleByVin}
      />
      <AddCustomOrManualVehicle
        customerId={customerId}
        onValueChange={resetMessages}
        submitCallback={submitCallback}
      />
      <Box padding={2} />
      <FormMessageNoPadding message={errorMessage} testId="ErrorMessage" />
      <FormMessageNoPadding
        message={infoMessage}
        severity={'info'}
        testId="InfoMessage"
      />
      {allVehiclesWithOwners && (
        <>
          {!vehicleWithOwners && (
            <Alert severity="info" sx={{ marginBottom: 2 }}>
              Select a Vehicle to continue
            </Alert>
          )}
          <Grid
            container
            spacing={{ xs: 1 }}
            columns={{ xs: 4, sm: 8, md: 12 }}>
            {allVehiclesWithOwners.map((entry) => (
              <Grid item xs={2} sm={4} md={4} key={entry.vehicle.id}>
                <ClickableCard onClick={() => setVehicleWithOwners(entry)}>
                  <VehicleCard
                    selected={entry === vehicleWithOwners}
                    vehicle={entry.vehicle}
                  />
                </ClickableCard>
              </Grid>
            ))}
          </Grid>
        </>
      )}
      {!allVehiclesWithOwners && vehicleWithOwners?.vehicle && (
        <Grid container spacing={{ xs: 1 }} columns={{ xs: 4, sm: 8, md: 12 }}>
          <Grid item xs={2} sm={4} md={4}>
            <Card variant="outlined">
              <VehicleCard
                selected={true}
                vehicle={vehicleWithOwners.vehicle}
              />
            </Card>
          </Grid>
        </Grid>
      )}
      {requiresLinkMethod && (
        <>
          <Box padding={1} />
          <FormMessageNoPadding
            message={`The vehicle has been linked to ${vehicleWithOwners!.customers
              .map((c) => {
                return formatName(c.information)
              })
              .join(', ')}`}
            severity={'warning'}
          />
        </>
      )}
      {requiresLinkMethod && (
        <FormControl>
          <RadioGroup
            aria-labelledby="link-method-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={linkMethod}
            onChange={(event, value) =>
              setLinkMethod(value as 'share' | 'transfer')
            }>
            <FormControlLabel
              value="share"
              control={<Radio />}
              label="Add the current customer as a co-owner"
            />
            <FormControlLabel
              value="transfer"
              control={<Radio />}
              label="Transfer the vehicle to the current customer"
            />
          </RadioGroup>
        </FormControl>
      )}
      {!linkDisabled && (
        <Button
          variant="contained"
          fullWidth
          sx={{ marginTop: 2 }}
          onClick={handleLink}>
          Link
        </Button>
      )}
    </Box>
  )
}
