import { Autocomplete, FormHelperText, Stack, TextField } from '@mui/material'
import React, { useEffect } from 'react'

import {
  CustomerInformationStateEnum,
  GetVehicleInfoFromLicensePlateRegisteredStateEnum,
  VehicleInformationRegisteredStateEnum
} from '../../sdk'

interface Props {
  required?: boolean
  value?:
    | CustomerInformationStateEnum
    | GetVehicleInfoFromLicensePlateRegisteredStateEnum
    | VehicleInformationRegisteredStateEnum
  errorMessage?: string
  onChange: (value: string | null) => void
  textVariant: 'outlined' | 'filled' | 'standard' | null
  size?: 'small' | 'medium' | undefined
}

export function UsStateSelect({
  required,
  errorMessage,
  value,
  onChange,
  textVariant,
  size
}: Props) {
  useEffect(() => {
    if (!value) {
      onChange(localStorage.getItem('selectedState'))
    }
  }, [onChange, value])

  return (
    <>
      <Stack>
        <Autocomplete
          id="UsStateSelect"
          getOptionLabel={(state) => state as string}
          autoHighlight
          value={value || localStorage.getItem('selectedState') || ''}
          onChange={(event: React.SyntheticEvent, value: string | null) => {
            localStorage.setItem('selectedState', value || '')
            onChange(value || '')
          }}
          options={Object.values(
            GetVehicleInfoFromLicensePlateRegisteredStateEnum
          ).map((v) => v as string)}
          renderInput={(params) => (
            <TextField
              {...params}
              label="State"
              fullWidth
              required={required}
              variant={textVariant || 'outlined'}
              size={size || 'medium'}
            />
          )}
          noOptionsText={'No options found'}
        />
        {errorMessage && <FormHelperText>{errorMessage}</FormHelperText>}
      </Stack>
    </>
  )
}
