import { Card, CardActionArea } from '@mui/material'
import React from 'react'

export const ClickableCard = ({
  onClick,
  children
}: {
  onClick?: () => void
  children?: React.ReactNode
}) => {
  return (
    <Card variant="outlined">
      <CardActionArea onClick={onClick || (() => {})}>
        {children}
      </CardActionArea>
    </Card>
  )
}
