import { ReactJSXElement } from '@emotion/react/types/jsx-namespace'
import { Box, Grid, TextField } from '@mui/material'

import {
  endYear,
  START_YEAR
} from '../VehicleModelSelect/useVehicleModelSelect'
import { YearSelect } from '../YearSelect'

interface Props {
  year: number
  setYear: React.Dispatch<React.SetStateAction<number>>
  make: string
  setMake: React.Dispatch<React.SetStateAction<string>>
  model: string
  setModel: React.Dispatch<React.SetStateAction<string>>
  subModel: string
  setSubModel: React.Dispatch<React.SetStateAction<string>>
  engine: string
  setEngine: React.Dispatch<React.SetStateAction<string>>
  bottomActions?: ReactJSXElement
}

const CustomVehicleForm = ({
  year,
  setYear,
  make,
  setMake,
  model,
  setModel,
  subModel,
  setSubModel,
  engine,
  setEngine,
  bottomActions
}: Props) => {
  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <YearSelect
            startYear={START_YEAR}
            endYear={endYear}
            required
            value={year}
            onChange={(e) => setYear(e.target.value as number)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            variant="outlined"
            size="small"
            label="Make"
            fullWidth
            value={make}
            onChange={(e) => setMake(e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            variant="outlined"
            size="small"
            label="Model"
            fullWidth
            value={model}
            onChange={(e) => setModel(e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            variant="outlined"
            size="small"
            label="Submodel"
            fullWidth
            value={subModel}
            onChange={(e) => setSubModel(e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            variant="outlined"
            size="small"
            label="Engine"
            fullWidth
            value={engine}
            onChange={(e) => setEngine(e.target.value)}
          />
        </Grid>
        {bottomActions && (
          <Grid item xs={6}>
            {bottomActions}
          </Grid>
        )}
      </Grid>
    </Box>
  )
}

export default CustomVehicleForm
