import { Alert, AlertColor, Box } from '@mui/material'
import React from 'react'

import { SECTION_PADDING } from '../../styles'

export interface FormMessageProps {
  message?: string
  severity?: AlertColor
  testId?: string
}

export const FormMessage: React.FC<FormMessageProps> = ({
  message,
  severity,
  testId
}) => {
  return message ? (
    <Box
      sx={{
        paddingBottom: SECTION_PADDING
      }}>
      <Alert severity={severity || 'error'} data-testid={testId}>
        {message}
      </Alert>
    </Box>
  ) : null
}
export const FormMessageNoPadding: React.FC<FormMessageProps> = ({
  message,
  severity,
  testId
}) => {
  return message ? (
    <Box>
      <Alert severity={severity || 'error'} data-testid={testId}>
        {message}
      </Alert>
    </Box>
  ) : null
}
