import AddIcon from '@mui/icons-material/Add'
import ConstructionIcon from '@mui/icons-material/Construction'
import EventIcon from '@mui/icons-material/Event'
import PersonIcon from '@mui/icons-material/Person'
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong'
import { Box, ClickAwayListener, Fab, Stack, Zoom } from '@mui/material'
import { useState } from 'react'

import theme from '../../styles/theme'

interface ExpandableFabProps {
  handleMenuItemClick: (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    index: number
  ) => void
  setOpenAddCustomerDialog: (open: boolean) => void
}

export const ExpandableFab = (props: ExpandableFabProps) => {
  const [expanded, setExpanded] = useState(false)
  return (
    <ClickAwayListener
      onClickAway={() => {
        setExpanded(false)
      }}>
      <>
        <Box
          sx={{
            position: 'fixed',
            bottom: theme.spacing(2),
            right: theme.spacing(2)
          }}>
          <Stack spacing={2} alignItems={'flex-end'}>
            {expanded && (
              <>
                <Zoom in={expanded} style={{ transitionDelay: '150ms' }}>
                  <Fab
                    color="primary"
                    aria-label="Appointment"
                    onClick={(event) => {
                      setExpanded(false)
                      props.handleMenuItemClick(event, 0)
                    }}
                    size="medium">
                    <EventIcon />
                  </Fab>
                </Zoom>
                <Zoom in={expanded} style={{ transitionDelay: '100ms' }}>
                  <Fab
                    color="primary"
                    aria-label="Customer"
                    onClick={() => {
                      setExpanded(false)
                      props.setOpenAddCustomerDialog(true)
                    }}
                    size="medium">
                    <PersonIcon />
                  </Fab>
                </Zoom>
                <Zoom in={expanded} style={{ transitionDelay: '50ms' }}>
                  <Fab
                    color="primary"
                    aria-label="Estimate"
                    onClick={(event) => {
                      setExpanded(false)
                      props.handleMenuItemClick(event, 1)
                    }}
                    size="medium">
                    <ReceiptLongIcon />
                  </Fab>
                </Zoom>
                <Zoom in={expanded} style={{ transitionDelay: '0ms' }}>
                  <Fab
                    color="primary"
                    aria-label="Repair Order"
                    //   variant="extended"
                    onClick={(event) => {
                      setExpanded(false)
                      props.handleMenuItemClick(event, 2)
                    }}
                    size="medium">
                    <ConstructionIcon />
                  </Fab>
                </Zoom>
              </>
            )}
            <Fab
              color="primary"
              aria-label="add"
              variant="circular"
              onClick={() => setExpanded((prev) => !prev)}>
              <AddIcon />
            </Fab>
          </Stack>
        </Box>
      </>
    </ClickAwayListener>
  )
}
